export const routesURL = {
  auth: {
    refreshToken: "auth/refresh-token",
    pinSignInKong: "auth/unlock",
    signInKong: "auth/login",
    singOut: "auth/revoke-access-token",
    verifyToken: "users/verify-token",

    passwordRecoverValidateCode: "password-recovery/validate-code",
    passwordRecoverValidateEmail: "password-recovery/email-code",
    passwordRecoverUpdatePassword: "password-recovery/change-password/",
  },
  inventory: {
    itemSearch: {
      getAllItems: (shopCode: string) =>
        `products/inventory?shopID=${shopCode}`,
      getItemDetails: (tenantName: string, itemId: string) =>
        `products/${tenantName}/${itemId}`,
      getItemLocations: (tenantName: string, shopID: string, itemId: string) =>
        `products/search-item?tenantName=${tenantName}&shopID=${shopID}&search=${itemId}`,
    },
  },
  report: {
    duplicatedItems: {
      getDuplicatedItems: "products/duplicated-items",
      editDuplicatedItems: "products/duplicated-items",
    },
    explore: {
      getFilters: "reports/filters/available/",
      getExploreItems: "reports/filter",
      getExploreItemsCSV: "reports/csv/",
      getAccountingSalesReport: "reports/sales/csv",
    },
    orders: {
      getOrders: "V2/orders/internal/search",
      refund: "V2/sales/refund",
      retryImport: (orderId: string) =>
        `V2/orders/internal/force-reprocess/${orderId}`,
      getSales: (orderId: string) =>
        `V2/orders/internal/${orderId}?loadSales=true&loadSalesProducts=true`,
      getRefundSalesItems: (orderId: string) =>
        `V2/orders/internal/${orderId}/refundable-sales-items?loadSales=true&loadSalesProducts=true`,
      cancelItem: (itemId: string) => `V2/sales/sale-items/${itemId}/cancel`,
      cancelLineItem: (orderID: string, orderLineID: string) =>
        `V2/orders/internal/${orderID}/orderline/${orderLineID}`,
      cancelOrder: (orderID: string) => `V2/sales/${orderID}/cancel`,
    },
  },
  settings: {
    stores: {
      deleteStorePrinter: (
        tenantName: string,
        shopID: string,
        printerID: string,
      ) => `/shops/${tenantName}/${shopID}/unlink-printer/${printerID}`,
      getAllTenants: "centralizer/tenants",
      getStoresByTenant: (tenantName: string) => `shops/${tenantName}/list`,
      getAllStores: "shops/list",
      getCustomAllStores: (storeProperties: string[]) =>
        `shops/list?fields=${storeProperties}&limit=1000&active=true`,
      getStore: (storeId: string) => `shops/${storeId}?loadRelations=printers`,
      editStore: (tenantName: string, storeId: string) =>
        `shops/${tenantName}/${storeId}`,
      updateStoreStatus: (tenantName: string, storeId: string) =>
        `shops/${tenantName}/${storeId}`,
    },
    users: {
      getAllUsers: "users",
      getUser: (userId: string) =>
        `users/${userId}?loadRelations=shopsGranted, defaultShop, defaultShop.printers, shopsGranted.printers`,
      createUser: "users",
      editUser: (userId: string) => `users/${userId}`,
      deleteUser: (userId: string) => `users/${userId}`,
      getAllPermissions: "users/permissions",
      getUserPermissions: (userId: string) => `users/permissions/${userId}`,
      editUserPermissions: (userId: string) =>
        `users/update-permissions/${userId}`,
      editUserRoles: (userId: string) => `users/${userId}`,
    },
    roles: {
      getAllRoles: "users/roles",
      getRole: (roleId: string) => `users/roles/${roleId}`,
      searchRoles: "users/roles/search",
      editRole: (roleId: string) => `users/roles/${roleId}`,
      createRole: "users/roles",
      deleteRole: (roleId: string) => `users/roles/${roleId}`,
    },
  },
};
