export const ShopifyCancelReason = [
  {
    id: "1",
    label: "Customer",
    value: "CUSTOMER",
  },
  {
    id: "2",
    label: "Declined",
    value: "DECLINED",
  },
  {
    id: "3",
    label: "Fraud",
    value: "FRAUD",
  },
  {
    id: "4",
    label: "Inventory",
    value: "INVENTORY",
  },
  {
    id: "5",
    label: "Other",
    value: "OTHER",
  },
  {
    id: "6",
    label: "Staff",
    value: "STAFF",
  },
];

export const EbayCancelReason = [
  {
    id: "1",
    label: "Address Issues",
    value: "ADDRESS_ISSUES",
  },
  {
    id: "2",
    label: "Buyer Asked Cancel",
    value: "BUYER_ASKED_CANCEL",
  },
  {
    id: "3",
    label: "Order Unpaid",
    value: "ORDER_UNPAID",
  },
  {
    id: "4",
    label: "Out of Stock or Cannot Cancel ",
    value: "OUT_OF_STOCK_OR_CANNOT_FULFILL",
  },
  {
    id: "5",
    label: "Unknown",
    value: "UNKNOWN",
  },
];

export const InternalReasonCancel = [
  {
    id: "1",
    label: "Select an option",
    value: "",
  },
  {
    id: "2",
    label: "N/A",
    value: "N/A",
  },
  {
    id: "3",
    label: "Damaged",
    value: "DAMAGED",
  },
  {
    id: "4",
    label: "Incorrect VR",
    value: "INCORRECT_VR",
  },
  {
    id: "5",
    label: "Incorrect Workorder",
    value: "INCORRECT_WORK_ORDER",
  },
  {
    id: "6",
    label: "Late",
    value: "LATE",
  },
  {
    id: "7",
    label: "Missed the Van",
    value: "MISSED_THE_VAN",
  },
  {
    id: "8",
    label: "Missing",
    value: "MISSING",
  },
  {
    id: "9",
    label: "Product Incomplete",
    value: "PRODUCT_INCOMPLETE",
  },
  {
    id: "10",
    label: "Tagged Wrong",
    value: "TAGGED_WRONG",
  },
];
