import React from "react";
import * as S from "./styles";
import { useTheme } from "styled-components";
import { CiSearch } from "react-icons/ci";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  errorMessage?: string;
  hasAsterisk?: boolean;
  title?: string;
  icon?: boolean;
  width?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ errorMessage, hasAsterisk, title, icon, width, ...rest }, ref) => {
    const { colors } = useTheme();

    return (
      <S.Container width={width}>
        {title && (
          <S.Title>
            {title}{" "}
            {hasAsterisk && (
              <label style={{ color: colors["red-900"] }}>*</label>
            )}
          </S.Title>
        )}
        <S.InputContent width={width}>
          {icon && (
            <CiSearch style={{ fontSize: "1.5rem", color: "#323232" }} />
          )}
          <input ref={ref} {...rest} />
        </S.InputContent>
        {!!errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
      </S.Container>
    );
  },
);

Input.displayName = "Input";
