import * as S from "./styles";
import * as Dialog from "@radix-ui/react-dialog";
import { Input } from "../../../../../../components/New/Input";
import { SwitchInput } from "../../../../../../components/Switch/SwitchInput";
import { IPrinter, IStore } from "../../../../../../@types/Stores";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PrinterSchema, TPrinterSchema } from "../../RegisterStore/schema";
import { Button } from "../../../../../../components/New/Button";
import { wrapperRequests } from "../../../../../../services/api";
import { routesURL } from "../../../../../../services/routesUrl";
import { useState } from "react";
import { useContextSelector } from "use-context-selector";
import { StoresContext } from "../../../../../../contexts/StoresContext";
import { toast } from "react-toastify";
import { SelectInput } from "../../../../../../components/SelectInput";
import { useNavigate } from "react-router-dom";

interface PrintersModalContentProps {
  printer?: IPrinter;
  storeData?: IStore;
}

export function PrintersModalContent({
  printer = {} as IPrinter,
  storeData,
}: PrintersModalContentProps) {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    watch,
  } = useForm<TPrinterSchema>({
    resolver: zodResolver(PrinterSchema),
    defaultValues: {
      id: printer?.id,
      activeForProductPrice: printer?.activeForProductPrice || false,
      activeForWarehouseLabel: printer?.activeForWarehouseLabel || false,
      active: printer?.active || false,
      activeForTransferCompleteLabel:
        printer?.activeForTransferCompleteLabel || false,
      activeForOrderItem: printer?.activeForOrderItem || false,
      activeForManualTransferCompleteLabel:
        printer?.activeForManualTransferCompleteLabel || false,
      size: printer.size || "Small - 2.25 x 1.25",
    },
  });

  const { fetchStore, setSelectedStore } = useContextSelector(
    StoresContext,
    (context) => {
      return context;
    },
  );

  const activeForProductPrice = watch("activeForProductPrice");
  const activeForWarehouseLabel = watch("activeForWarehouseLabel");
  const active = watch("active");
  const activeForTransferCompleteLabel = watch(
    "activeForTransferCompleteLabel",
  );
  const activeForOrderItem = watch("activeForOrderItem");
  const activeForManualTransferCompleteLabel = watch(
    "activeForManualTransferCompleteLabel",
  );

  const navigate = useNavigate();

  async function handleCreatePrinter(data: TPrinterSchema) {
    setLoading(true);
    const initialPrinters = storeData?.printers || [];

    const removeUpdatedPrinter = initialPrinters.filter(
      (printer) => printer.id !== data.id,
    );

    if (storeData?.tenantName && storeData.code) {
      try {
        await wrapperRequests(
          routesURL.settings.stores.editStore(
            storeData?.tenantName,
            storeData.code,
          ),
          "PUT",
          {
            data: {
              printers: [...removeUpdatedPrinter, data],
            },
          },
        );

        setSelectedStore({} as IStore);
        reset();

        navigate(`/settings/stores/${storeData.id}`);

        const successMessage = printer.id
          ? "🏬 Printer edited successfully!"
          : "🏬 Printer created successfully!";

        toast.success(successMessage, {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        toast.error("🏬 Error occurred while updating the printers!", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        throw Error(String(error));
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <S.ModalFormContent>
      <S.ModalAlign style={{ alignItems: "center" }}>
        <Input
          title="Serial Number"
          defaultValue={printer?.serialNumber}
          {...register("serialNumber")}
          errorMessage={errors.serialNumber?.message}
          hasAsterisk
        />

        <SwitchInput
          title="Product Price"
          label={activeForProductPrice ? "On" : "Off"}
          checked={!!activeForProductPrice}
          onChange={() =>
            setValue("activeForProductPrice", !activeForProductPrice)
          }
        />
      </S.ModalAlign>

      <S.ModalAlign style={{ alignItems: "center" }}>
        <Input
          title="Tag"
          defaultValue={printer?.tag}
          {...register("tag")}
          errorMessage={errors.tag?.message}
          hasAsterisk
        />

        <SwitchInput
          title="Warehouse Label"
          label={activeForWarehouseLabel ? "On" : "Off"}
          checked={!!activeForWarehouseLabel}
          onChange={() =>
            setValue("activeForWarehouseLabel", !activeForWarehouseLabel)
          }
        />
      </S.ModalAlign>

      <S.ModalAlign>
        <div className="marginActive">
          <SwitchInput
            title="Active"
            label={active ? "On" : "Off"}
            checked={!!active}
            {...register("active")}
            onChange={() => setValue("active", !active)}
          />
        </div>

        <SwitchInput
          title="Transfer Label"
          label={activeForTransferCompleteLabel ? "On" : "Off"}
          checked={!!activeForTransferCompleteLabel}
          onChange={() =>
            setValue(
              "activeForTransferCompleteLabel",
              !activeForTransferCompleteLabel,
            )
          }
        />
      </S.ModalAlign>

      <S.ModalAlign>
        <div className="marginOrder">
          <SwitchInput
            title="Order Item"
            label={activeForOrderItem ? "On" : "Off"}
            checked={!!activeForOrderItem}
            {...register("activeForOrderItem")}
            onChange={() => setValue("activeForOrderItem", !activeForOrderItem)}
          />
        </div>

        <SwitchInput
          title="Manual Transfer Label"
          label={activeForManualTransferCompleteLabel ? "On" : "Off"}
          checked={!!activeForManualTransferCompleteLabel}
          onChange={() =>
            setValue(
              "activeForManualTransferCompleteLabel",
              !activeForManualTransferCompleteLabel,
            )
          }
        />
      </S.ModalAlign>

      <S.ModalAlign>
        <div className="marginSize">
          <SelectInput
            title="Size"
            variant="resized"
            defaultValue={printer?.size || "Select"}
            options={[
              {
                id: "1",
                value: "Small - 2.25 x 1.25",
                label: "Small - 2.25 x 1.25",
              },
              {
                id: "2",
                value: "Large - 2.25 x 3",
                label: "Large - 2.25 x 3",
              },
              {
                id: "3",
                value: "ExtraLarge - 4 x 6",
                label: "ExtraLarge - 4 x 6",
              },
            ]}
            {...register("size")}
          />
        </div>
      </S.ModalAlign>

      <S.ButtonContainer>
        <Dialog.Close asChild>
          <Button
            title="Cancel"
            variant="ghost"
            onClick={() => clearErrors()}
            disabled={loading}
          />
        </Dialog.Close>

        <Button
          type="button"
          title="Save"
          isLoading={loading}
          onClick={handleSubmit(handleCreatePrinter)}
        />
      </S.ButtonContainer>
    </S.ModalFormContent>
  );
}
