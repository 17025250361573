import styled from "styled-components";

export const CheckboxContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  gap: 0.75rem;
`;

export const CircleCheckboxLabel = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  color: ${(props) => props.theme.newColors["gray-900"]};
`;
