import styled from "styled-components";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding: 0 1rem;

  background-color: ${(props) => props.theme.newColors["white-500"]};
  border: 1px solid ${(props) => props.theme.newColors["gray-500"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};

  input {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.newColors["white-500"]};
    border: none;
    border-radius: ${(props) => props.theme.borderRadius.SM};
    padding: 0.5rem;

    color: ${(props) => props.theme.newColors["gray-900"]};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};
  }
`;

export const DropdownContent = styled(DropdownMenu.Content)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: ${(props) => props.theme.newColors["white-500"]};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: ${(props) => props.theme.borderRadius.SMM};

  padding: 0.875rem 1rem;
  margin-right: 2rem;
  margin-top: -2rem;

  width: 15.8rem;
`;

export const Title = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  color: ${(props) => props.theme.newColors["gray-900"]};
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.75rem;
`;
