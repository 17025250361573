import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import styled, { css } from "styled-components";

interface ExploreSideFilterMenuProps {
  showFilters: boolean;
}

export const ExploreSideFilterMenu = styled.div<ExploreSideFilterMenuProps>`
  width: 15.5rem;
  min-height: calc(100vh - 5rem);
  height: 100%;

  background-color: ${(props) => props.theme.newColors["white-300"]};
  border-right: 1px solid ${(props) => props.theme.colors["gray-400"]};

  ${(props) =>
    !props.showFilters &&
    css`
      width: 2.5rem;
    `};
`;

export const ExploreSideFilterInputContainer = styled.div<ExploreSideFilterMenuProps>`
  padding: 1.5rem;
  padding-bottom: 0;

  div {
    display: flex;
    justify-content: space-between;
  }

  ${(props) =>
    !props.showFilters &&
    css`
      padding: 0.5rem;
      padding-top: 1.5rem;
    `};
`;

export const ReduceArrowIcon = styled(TfiArrowCircleLeft).attrs({
  size: 24,
})`
  cursor: pointer;

  color: ${(props) => props.theme.colors["gray-600"]};
`;

export const ExpandArrowIcon = styled(TfiArrowCircleRight).attrs({
  size: 24,
})`
  cursor: pointer;

  color: ${(props) => props.theme.colors["gray-600"]};
  
`;

export const ExploreSideFilterMenuTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize.SM};

  margin-bottom: 1.5rem;
`;

export const ExploreSideFilterButtonsContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

interface ExploreSideFilterTabButtonProps {
  selected: boolean;
}

export const ExploreFiltersLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 2rem;
`;

export const ExploreSideFilterTabButton = styled.button<ExploreSideFilterTabButtonProps>`
  height: 3rem;
  width: 100%;

  border: none;
  background-color: transparent;

  color: ${(props) => props.theme.colors["blue-600"]};

  border-bottom: 1px solid ${(props) => props.theme.colors["blue-600"]};

  &:hover {
    background-color: ${(props) => props.theme.colors["blue-150"]};
    transition: all 0.2s;
  }

  ${(props) =>
    !props.selected &&
    css`
      color: ${(props) => props.theme.colors["gray-800"]};
      border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
      font-weight: ${(props) => props.theme.fontWeight.Regular};
    `};
`;

export const ExploreFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExploreFiltersContent = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;

interface ExploreFiltersTitleButtonProps {
  selected?: boolean;
  
}

export const ExploreFiltersTitleButton = styled.button<ExploreFiltersTitleButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${(props) => props.theme.fontWeight.Bold};

  padding: 0 1rem;

  border: 0;

  background-color: transparent;

  height: 2.5rem;
  width: 100%;

  div {
    display: flex;
    align-items: center;

    text-transform: capitalize;

    svg {
      margin-right: 4px;
    }
  }
  span {
    background-color: ${(props) => props.theme.colors["blue-150"]};
    padding: 4px 12px;
    border-radius: ${(props) => props.theme.borderRadius.XXLG};

    color: ${(props) => props.theme.colors["blue-600"]};
    font-weight: ${(props) => props.theme.fontWeight.Bold};
  }
`;

export const ExploreFiltersCategoryButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 1rem;

  transition: all 2s ease;
`;

interface ExploreFiltersCategoryButtonProps {
  selected: boolean;
}

export const ExploreFiltersCategoryButton = styled.button<ExploreFiltersCategoryButtonProps>`
  display: flex;
  width: 100%;
  padding: 0.5rem 0;

  border: 0;
  background-color: transparent;
  text-align: left;

  span {
    margin-right: 0.5rem;
  }

  color: ${(props) => props.theme.colors["gray-800"]};

  &:hover {
    filter: brightness(0.2);
  }

  ${(props) =>
    props.selected &&
    css`
      color: ${props.theme.colors["blue-600"]};
    `};
`;
