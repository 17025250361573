import React from "react";
import * as S from "./styles";
import { SwitchButton } from "..";

interface SwitchInput {
  checked: boolean;
  label: string;
  onChange: () => void;
  title: string;
  width?: string;
}

export const SwitchInput = React.forwardRef<HTMLButtonElement, SwitchInput>(
  ({ checked, label, onChange, title, width }, ref) => {
    return (
      <S.SwitchContainer width={width}>
        <span>{title}</span>
        <button ref={ref} onClick={onChange} type="button">
          <SwitchButton onChange={onChange} checked={checked} />
          <span>{label}</span>
        </button>
      </S.SwitchContainer>
    );
  },
);
