import styled from "styled-components";
import { FaCheck } from "react-icons/fa";

export const Container = styled.button`
  display: flex;
  width: 1rem;
  height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  justify-content: center;
  align-items: center;

  border: 2px solid ${(props) => props.theme.newColors["gray-900"]};
  border-radius: 4px;
  background-color: transparent;
  padding: 1px;
`;

export const CheckIcon = styled(FaCheck)`
  padding: 0;
  color: ${(props) => props.theme.newColors["gray-900"]};
`;
