import { useEffect, useState } from "react";
import { Button } from "../../../../components/New/Button";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { SelectInput } from "../../../../components/SelectInput";
import { useAccountingSalesReport } from "../../../../hooks/useAccountingSalesReport";
import { monthsOptions } from "./monthsOptions";
import * as S from "./styles";

interface AccountingSalesReportProps {
  initialMonth?: string;
  initialYear?: string;
  title?: string;
  description?: string;
}

export function SalesTaxReport({
  initialMonth = "1",
  initialYear = "2024",
  title = "Sales Tax Report",
  description = "Download sales tax report",
}: AccountingSalesReportProps) {
  const currentYear = new Date().getFullYear();

  const [reportFilter, setReportFilter] = useState({
    month: initialMonth,
    year: initialYear,
  });

  const [yearsOptions, setYearsOptions] = useState<
    {
      id: string;
      label: string;
      value: string;
    }[]
  >([]);

  const { getAccountingSalesReport, useAccountingSalesReportLoading } =
    useAccountingSalesReport();

  function handleGetAccountingSalesReport() {
    const month = Number(reportFilter.month);
    const year = Number(reportFilter.year);

    const formattedMonth = month < 10 ? `0${month}` : month;

    const startDate = `${year}-${formattedMonth}-01`;

    const nextMonth = month === 12 ? 1 : month + 1;
    const nextYear = month === 12 ? year + 1 : year;
    const formattedNextMonth = nextMonth < 10 ? `0${nextMonth}` : nextMonth;

    const endDate = `${nextYear}-${formattedNextMonth}-01`;

    getAccountingSalesReport({ startDate, endDate });
  }

  function getYears() {
    for (let year = currentYear; year >= 2023; year--) {
      setYearsOptions((currentValue) => [
        ...currentValue,
        {
          id: year.toString(),
          label: year.toString(),
          value: year.toString(),
        },
      ]);
    }
  }

  useEffect(() => {
    getYears();
  }, []);

  return (
    <S.Container>
      <S.HeaderContainer>
        <PageHeader
          title={title}
          description={description}
          refreshFunction={() => {}}
          downloadFunction={handleGetAccountingSalesReport}
          loading={{
            type: "csv",
            isLoading: useAccountingSalesReportLoading,
          }}
          customBreadcrumbString="Sales Tax Report"
        />
      </S.HeaderContainer>

      <S.Content>
        <div>
          <S.FilterTitle>
            Select month <span className="required">*</span>
          </S.FilterTitle>
          <S.SelectInputWrapper>
            <SelectInput
              options={monthsOptions}
              variant="resized"
              onChange={(e) =>
                setReportFilter((currentState) => ({
                  ...currentState,
                  month: e.target.value || currentState.month,
                }))
              }
              width="20rem"
            />
          </S.SelectInputWrapper>
        </div>
        <div>
          <S.FilterTitle>
            Select year <span className="required">*</span>
          </S.FilterTitle>
          {yearsOptions && (
            <S.SelectInputWrapper>
              <SelectInput
                options={yearsOptions}
                placeholder={reportFilter.year}
                variant="resized"
                onChange={(e) =>
                  setReportFilter((currentState) => ({
                    ...currentState,
                    year: e.target.value || currentState.year,
                  }))
                }
                width="20rem"
              />
            </S.SelectInputWrapper>
          )}
        </div>

        <Button
          title="Download"
          disabled={useAccountingSalesReportLoading}
          isLoading={useAccountingSalesReportLoading}
          onClick={
            !useAccountingSalesReportLoading
              ? handleGetAccountingSalesReport
              : undefined
          }
          style={{
            cursor: useAccountingSalesReportLoading ? "not-allowed" : "pointer",
            opacity: useAccountingSalesReportLoading ? 0.7 : 1,
          }}
        />
      </S.Content>
    </S.Container>
  );
}
