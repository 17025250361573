import styled from "styled-components";
import Switch from "react-switch";

export const SwitchStyled = styled(Switch).attrs((props: { checked: boolean }) => ({
  onColor: "#0063F9",
  onHandleColor: "#ffffff",
  handleDiameter: 20,
  uncheckedIcon: false,
  checkedIcon: false,
  height: 18,
  width: 32,
  offColor: props.checked ? "#0063F9" : "#323232",
}))`
  .react-switch-handle,
  .react-switch-bg {
    cursor: pointer !important;
  }
`;
