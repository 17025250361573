import { ButtonHTMLAttributes, forwardRef } from "react";
import * as S from "./styles";
import { LuTrash2 } from "react-icons/lu";

type DeleteIconProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const DeleteIcon = forwardRef<HTMLButtonElement, DeleteIconProps>(
  ({ ...rest }, ref) => {
    return (
      <S.ButtonContainer ref={ref} {...rest}>
        <LuTrash2 size={22} style={{ cursor: "pointer" }} />
      </S.ButtonContainer>
    );
  },
);
