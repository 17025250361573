import { MdStorefront } from "react-icons/md";
import { LuUsers } from "react-icons/lu";
import { PiIdentificationBadge } from "react-icons/pi";
import { CardLinkButton } from "../../components/CardLinkButton";
import { useAuth } from "../../hooks/auth";
import { permissions } from "../../shared/Permissions";

import * as S from "./styles";

export function Settings() {
  const { user } = useAuth();

  return (
    <S.SettingsContainer>
      <S.SettingsCardButtonContainer>
        {user.user.permissions.includes(permissions.settings.user) && (
          <CardLinkButton
            href="/settings/users"
            title="Users"
            description="View, add and edit users"
          >
            <LuUsers />
          </CardLinkButton>
        )}
        {user.user.permissions.includes(permissions.settings.stores) && (
          <CardLinkButton
            href="/settings/stores"
            title="Stores"
            description="Create stores or manage active stores in the system"
          >
            <MdStorefront />
          </CardLinkButton>
        )}
        {user.user.permissions.includes(permissions?.settings?.roles) && (
          <CardLinkButton
            href="/settings/roles"
            title="Roles"
            description="Create and designate roles for each user, manage access and permissions"
          >
            <PiIdentificationBadge />
          </CardLinkButton>
        )}
      </S.SettingsCardButtonContainer>
    </S.SettingsContainer>
  );
}
