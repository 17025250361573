import { PageHeader } from "../../../../components/Headers/PageHeader";
import { SideFilters } from "./components/SideFilters";

import { useState } from "react";
import { useContextSelector } from "use-context-selector";
import { ExploreItemsContext } from "../../../../contexts/ExploreItemsContext";
import { ExploreItemsHeader } from "./components/ExploreItemsHeader";
import { ExploreTable } from "./components/ExploreTable";
import { FilterDetails } from "./components/FilterDetails";
import * as S from "./styles";

export function DynamicReport() {
  const [selectedPage, setSelectedPage] = useState<"filters" | "table">(
    "filters",
  );

  const { fetchExploreItems, fetchExploreItemsCSV, exploreItemsLoading } =
    useContextSelector(ExploreItemsContext, (context) => {
      return context;
    });

  function handleSelectPage(page: "filters" | "table") {
    setSelectedPage(page);
  }

  return (
    <S.ExploreContainer>
      <SideFilters />

      <S.ExploreContentContainer>
        <PageHeader
          title="Dynamic Report"
          description={
            "Filter and search for information and create a complete report"
          }
          hasButtons
          hasBorder={false}
          refreshFunction={() => {
            handleSelectPage("table");
            fetchExploreItems();
          }}
          downloadFunction={() => fetchExploreItemsCSV()}
          buttonType="search"
          loading={exploreItemsLoading}
        />

        <ExploreItemsHeader
          handleSelectPage={handleSelectPage}
          page={selectedPage}
        />

        {/* {exploreItemsError.message && (
          <S.ExploreItemsError>{exploreItemsError.message}</S.ExploreItemsError>
        )} */}

        {selectedPage === "filters" ? (
          <FilterDetails />
        ) : (
          <ExploreTable updateSelectedPage={handleSelectPage} />
        )}
      </S.ExploreContentContainer>
    </S.ExploreContainer>
  );
}
