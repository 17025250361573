import styled from "styled-components";

export const InventoryContainer = styled.main`
  flex: 1;
  display: flex;
  flex-direction: row;

  width: 100%;
  min-height: 100vh;

  padding: 2rem;
  gap: 1.5rem;
`;
