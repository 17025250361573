import * as S from "./styles";

interface ExploreItemsHeaderProps {
  page: "filters" | "table";
  handleSelectPage: (page: "filters" | "table") => void;
}

export function ExploreItemsHeader({
  handleSelectPage,
  page,
}: ExploreItemsHeaderProps) {
  return (
    <S.CreateStoreStepsContainer>
      <S.StepsButton
        active={page === "filters"}
        onClick={() => handleSelectPage("filters")}
      >
        Selected filters
      </S.StepsButton>
      <S.StepsButton
        active={page === "table"}
        onClick={() => handleSelectPage("table")}
      >
        Table
      </S.StepsButton>
    </S.CreateStoreStepsContainer>
  );
}
