import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    --sideBarWidth: 15rem;
    --sideBarWidthResponsive: 4.75rem;
    --text-title: #363f5f;
    --text-body: #232A3B;
    --text-login: #070707;
    --gray-400: #e6e6e6;
    --gray-500: #E2DFDF;
    --gray-600: #C4C4C4;
    --gray-800: #606060;
    --green: #228B22;
    --background: #FAFAFA;
    --input-border: #dbdbdb;
    --menu-background: #070707;  
    --red: #e60000;  
    --white: #ffffff;
    --button-background: #737373;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;   

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s !important;
    }

    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
  }  

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: ${(props) => props.theme.newColors["white-300"]};    
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  input {
    font-family: 'Roboto', sans-serif;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
