export const RefundReason = [
  {
    id: "1",
    label: "Customer",
    value: "customer",
  },
  {
    id: "2",
    label: "Damage",
    value: "damage",
  },
  {
    id: "3",
    label: "Restock",
    value: "restock",
  },
  {
    id: "4",
    label: "Other",
    value: "other",
  },
];

export const RefundPercentage = [
  {
    id: "1",
    label: "100%",
    value: "100%",
  },
  {
    id: "2",
    label: "90%",
    value: "90%",
  },
  {
    id: "3",
    label: "80%",
    value: "80%",
  },
  {
    id: "4",
    label: "70%",
    value: "70%",
  },
  {
    id: "5",
    label: "60%",
    value: "60%",
  },
  {
    id: "6",
    label: "50%",
    value: "50%",
  },
];

export const StockLocationInitialValue = [
  {
    id: "63647232c09b1fa35103bec6",
    tenantName: "Incycle",
    name: "Pomona Fulfillment Center",
    code: "9",
  },
];

export const RefundCondition = [
  {
    id: "1",
    label: "New",
    value: "New",
  },
  {
    id: "2",
    label: "Blem",
    value: "Blem",
  },
  {
    id: "3",
    label: "Missing Parts",
    value: "Missing Parts",
  },
  {
    id: "4",
    label: "No Box",
    value: "No Box",
  },
  {
    id: "5",
    label: "Open Box",
    value: "Open Box",
  },
  {
    id: "6",
    label: "Pre-Owned",
    value: "Pre-Owned",
  },
  {
    id: "7",
    label: "Take-Off",
    value: "Take-Off",
  },
];

export const EbayRefundReason = [
  {
    id: "1",
    label: "Buyer cancel",
    value: "BUYER_CANCEL",
  },
  {
    id: "2",
    label: "Item not received",
    value: "ITEM_NOT_RECEIVED",
  },
  {
    id: "3",
    label: "Seller cancel",
    value: "SELLER_CANCEL",
  },
];

export const InternalReasonRefund = [
  {
    id: "1",
    label: "Select an option",
    value: "",
  },
  {
    id: "2",
    label: "N/A",
    value: "N/A",
  },
  {
    id: "3",
    label: "Damaged",
    value: "DAMAGED",
  },
  {
    id: "4",
    label: "Incorrect VR",
    value: "INCORRECT_VR",
  },
  {
    id: "5",
    label: "Incorrect Workorder",
    value: "INCORRECT_WORK_ORDER",
  },
  {
    id: "6",
    label: "Late",
    value: "LATE",
  },
  {
    id: "7",
    label: "Missed the Van",
    value: "MISSED_THE_VAN",
  },
  {
    id: "8",
    label: "Missing",
    value: "MISSING",
  },
  {
    id: "9",
    label: "Product Incomplete",
    value: "PRODUCT_INCOMPLETE",
  },
  {
    id: "10",
    label: "Tagged Wrong",
    value: "TAGGED_WRONG",
  },
];
