import { DayPickerProps } from "react-day-picker";
import "react-day-picker/dist/style.css";
import * as S from "./styles";

interface Props {
  open?: boolean;
  width?: string;
}

export const DatePicker = ({ open, width, ...rest }: DayPickerProps & Props) => {
  const currentYear = new Date().getFullYear();

  return open ? (
    <S.DayPickerComponent
      captionLayout="dropdown-buttons"
      fromYear={2020}
      toYear={currentYear}
      width={width}
      {...rest}
    />
  ) : null;
};
