import { ButtonHTMLAttributes } from "react";
import CheckedCircle from "../../../assets/icons/checked-circle.svg";
import UncheckedCircle from "../../../assets/icons/unchecked-circle.svg";
import * as S from "./styles";

interface CircleCheckboxProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  checked: boolean;
  label?: string;
  onClick: () => void;
}

export const CircleCheckbox = ({
  checked,
  label,
  onClick,
  ...rest
}: CircleCheckboxProps) => {
  return (
    <S.CheckboxContainer onClick={onClick} {...rest}>
      {checked ? (
        <img src={CheckedCircle} alt="checked-circle" />
      ) : (
        <img src={UncheckedCircle} alt="unchecked-circle" />
      )}

      <S.CircleCheckboxLabel>{label}</S.CircleCheckboxLabel>
    </S.CheckboxContainer>
  );
};
