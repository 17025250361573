import styled, { css } from "styled-components";

export const Container = styled.main`
  padding: 2rem;
`;

export const Content = styled.section`
  margin-top: 1.5rem;
`;

interface RolesContainerProps {
  role?: string;
}

export const RolesContainer = styled.div<RolesContainerProps>`
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: ${(props) => props.theme.newColors["gray-550"]};
  border-radius: 200px;

  p {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    color: ${(props) => props.theme.colors["gray-850"]};
  }

  ${(props) =>
    (props.role === "Admin" || props.role === "Administrator") &&
    css`
      background-color: ${(props) => props.theme.newColors["yellow-500"]};
    `};

  ${(props) =>
    props.role === "Manager" &&
    css`
      background-color: ${(props) => props.theme.newColors["green-300"]};
    `};

  ${(props) =>
    props.role === "Default" &&
    css`
      background-color: ${(props) => props.theme.newColors["blue-500"]};
    `};

  ${(props) =>
    props.role === "Custom" &&
    css`
      background-color: ${(props) => props.theme.newColors["gray-550"]};
    `};

  ${(props) =>
    props.role === "Associate" &&
    css`
      background-color: ${(props) => props.theme.newColors["pink-500"]};
    `};

  ${(props) =>
    props.role === "Finance" &&
    css`
      background-color: ${(props) => props.theme.newColors["green-500"]};
    `};

  ${(props) =>
    props.role === "Operations" &&
    css`
      background-color: ${(props) => props.theme.newColors["blue-400"]};
    `};

  ${(props) =>
    props.role === "Disabled" &&
    css`
      background-color: ${(props) => props.theme.newColors["gray-100"]};
    `};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 0.5rem;
`;
