import styled, { css } from "styled-components";

export const Title = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  color: ${(props) => props.theme.newColors["black-900"]};

  margin-bottom: 0.25rem;
`;

interface DropdownContainerProps {
  disabled?: boolean;
}

export const DropdownContainer = styled.div<DropdownContainerProps>`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};
  cursor: pointer;
  height: 2rem;
  padding: auto 0;
  position: relative;
  width: 100%;

  &:hover {
    outline: 1px solid ${(props) => props.theme.colors["gray-650"]};
    transition: outline 0.2s;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  input {
    border: none;
    background-color: transparent;
    color: ${(props) => props.theme.colors["gray-850"]};
    font-size: ${(props) => props.theme.fontSize.XXXS};
    height: 2rem;
    padding: 0 0.5rem;
    width: 100%;
    cursor: pointer;
  }

  .arrow-icon {
    position: absolute;
    right: 0.5rem;
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors["gray-850"]};
    cursor: pointer;
  }
`;

export const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 100px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  list-style: none;

  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
    padding: 1.25rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.white};
    border: 0.06rem solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors["gray-700"]};
  }
`;

export const OptionItem = styled.li`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  color: ${(props) => props.theme.colors["gray-850"]};
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.newColors["gray-200"]};
  }
`;

export const NoOptionsMessage = styled.div`
  padding: 8px 12px;
  color: ${(props) => props.theme.colors["gray-850"]};
  font-size: 0.9rem;
  text-align: center;
`;
