import styled, { css } from "styled-components";

export const Content = styled.section`
  display: flex;
  flex-direction: column;
`;

export const ModalDescription = styled.span`
  color: ${(props) => props.theme.newColors["gray-900"]};
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: ${(props) => props.theme.fontSize.XS};
  color: ${(props) => props.theme.colors["gray-950"]};

  margin-bottom: 1.5rem;
`;

export const SelectAllButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  background-color: transparent;
  border: none;

  padding: 0.5rem 0.75rem;
  border-radius: ${(props) => props.theme.borderRadius.SM};
  border: 1px solid ${(props) => props.theme.colors["gray-600"]};

  max-height: 2rem;

  span {
    height: 1.1rem;
    color: ${(props) => props.theme.newColors["gray-900"]};
  }

  &:hover {
    box-shadow: 0px 2px 5px 0px rgba(37, 30, 30, 0.25);
    transition: all 0.2s;
  }
`;

interface PermissionsContentProps {
  permissionsLoading: boolean;
}

export const PermissionsContent = styled.div<PermissionsContentProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  gap: 1rem;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors["gray-600"]};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors["gray-400"]};
    border-radius: 4px;
  }

  ${(props) =>
    props.permissionsLoading &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;

export const PermissionsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const PermissionType = styled.h4`
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.newColors["gray-900"]};

  margin-bottom: 0.75rem;
`;

export const PermissionsViewControl = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
`;

interface MessageProps {
  error: boolean;
}

export const Message = styled.span<MessageProps>`
  text-align: center;
  margin-top: 4px;
  font-weight: ${(props) => props.theme.fontWeight.Semibold};

  color: ${(props) => props.theme.colors["green-800"]};

  ${(props) =>
    props.error &&
    css`
      color: ${props.theme.colors["red-500"]};
    `};
`;

export const PermissionPage = styled.h4`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  color: ${(props) => props.theme.colors["gray-950"]};

  margin-bottom: 0.75rem;
`;

export const PermissionsList = styled.ul`
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  padding-bottom: 1.5rem;

  & > li {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
`;

export const PermissionItem = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;

  label {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    color: ${(props) => props.theme.newColors["gray-900"]};
    cursor: pointer;
  }

  input:disabled {
    cursor: not-allowed;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExpandGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-left: 2.75rem;

  & > div:nth-child(2) {
    margin-left: 1.25rem;
  }

  & > div:nth-child(3) {
    margin-left: 2.5rem;
  }
`;
