import styled from "styled-components";

export const Container = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  position: relative;
  width: ${({ width }) => width || "100%"};
`;

export const Title = styled.span`
  color: ${(props) => props.theme.newColors["black-900"]};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  line-height: 1.3rem;
`;

export const InputContent = styled.div<{ width?: string }>`
  height: 2rem;
  width: ${({ width }) => width || "100%"};

  background-color: ${(props) => props.theme.newColors.white};
  border: 1px solid ${(props) => props.theme.newColors["gray-500"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};

  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: ${(props) => props.theme.borderRadius.SM};
    padding: 0.5rem;

    color: ${(props) => props.theme.newColors["gray-900"]};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};

    &:disabled {
      cursor: not-allowed;
      background-color: ${(props) => props.theme.newColors["gray-300"]};
    }
  }

  svg {
    margin-left: 0.5rem;
    color: ${(props) => props.theme.newColors["gray-600"]};
  }

  &:hover {
    transition: all 0.2s;
    border: 1px solid ${(props) => props.theme.newColors["gray-600"]};
  }
`;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors["red-500"]};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.25rem;
  white-space: nowrap;
  z-index: 1;
`;
