export const colors = {
  "black-600": "#323232",
  "black-700": "#27282B",
  "black-750": "#2E2E2E",
  "black-800": "#141414",
  "black-850": "#0A0A0A",
  "black-900": "#101010",
  "black-925": "#030303",
  "black-950": "#0a0a0a",
  "black": "#000000",

  "blue-100": "#DAF4FB",
  "blue-150": "#EBF2FF",
  "blue-200": "#CCDFFF",
  "blue-300": "#94DCEE",
  "blue-350": "#ACD2FF",
  "blue-400": "#54C0DC",
  "blue-450": "#549DFC",
  "blue-500": "#66A0FF",
  "blue-600": "#0063F9",
  "blue-650": "#004DCC",
  "blue-675": "#0056B1",
  "blue-900": "#0056C9",
  "blue-700": "#256373",
  "blue-800": "#003080",

  "cyan-200": "#00E6F9",
  "cyan-300": "#D6DAE0",
  "cyan-400": "#8690A2",
  "cyan-500": "#374761",
  "cyan-700": "#1A2332",

  "brown-300": "#FFEBCC",
  "brown-400": "#FFC166",
  "brown-500": "#F99500",
  "brown-700": "#804C00",

  "gray-100":" #CDCDCD",
  "gray-200": "#f7f7f7",
  "gray-300": "#E5E5E5",
  "gray-400": "#DADADA",
  "gray-600": "#BEBEBE",
  "gray-650": "#979797",
  "gray-700": "#A1A1A1",
  "gray-750": "#9C9C9C",
  "gray-800": "#6D6D6D",
  "gray-850": "#323232",
  "gray-900": "#373737",
  "gray-950": "#2E2E2E",

  "green-100": "#E4FCEB",
  "green-150": "#D1F5DB",
  "green-200": "#D6FACC",
  "green-300": "#B0F2C4",
  "green-400": "#80E49E",
  "green-450": "#FFB266",
  "green-500": "#88EA66",
  "green-550": "#84DD10",
  "green-600": "#3DD209",
  "green-650": "#0EBE40",
  "green-675": "#086823",
  "green-700": "#3D764E",
  "green-800": "#1B7000",

  "golden-500": "#F8BF00",

  "orange-300": "#FFEDD5",
  "orange-400": "#FDC886",
  "orange-450": "#F8A05E",
  "orange-500": "#F2A23F",
  "orange-700": "#7F5219",

  "pink-200": "#FFB2B9",
  "pink-600": "#EE41AA",

  "purple-300": "#F2E3F9",
  "purple-400": "#D6AFEC",
  "purple-500": "#B97EDA",
  "purple-700": "#5D3C70",

  "red-200": "#AF2A1A",
  "red-300": "#FFCCCC",
  "red-400": "#FF6666",
  "red-425": "#E97300",
  "red-450": "#FF3C1F",
  "red-475": "#FC0B37",
  "red-500": "#F90000",
  "red-700": "#800000",
  "red-800": "#962215",
  "red-900": "#FF1B1B",

  "white": "#FFFFFF",
  "white-300": "#F7F7F7",
} as const;
