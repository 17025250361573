import { DayPicker } from "react-day-picker";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface DayPickerProps {
  width?: string;
}

export const DayPickerComponent = styled(DayPicker)<DayPickerProps>`
  padding: 0.5rem 1rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;

  position: absolute;
  top: 1.5rem;
  left: -1rem;
  z-index: 1;

  animation: ${fadeIn} 0.3s ease-in forwards;
  width: ${({ width }) => width || "auto"}; 

  .rdp-dropdown {
    cursor: pointer;
  }

  .rdp-caption_dropdowns select {
    background-color: ${(props) => props.theme.colors.white};

    option {
      color: ${(props) => props.theme.colors["gray-800"]};
      font-size: ${(props) => props.theme.fontSize.XS};
    }
  }

  .rdp-caption_label {
    color: ${(props) => props.theme.colors["black-700"]};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    background-color: ${(props) => props.theme.colors["blue-600"]};
  }
`;
