import { Route, Routes } from "react-router";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./hooks/auth";
import { DefaultLayout } from "./layouts/DefaultLayout";
import { permissions } from "./shared/Permissions";

// pages
import { Dashboard } from "./pages/Dashboard";
import { Inventory } from "./pages/Inventory";
import { ItemSearch } from "./pages/Inventory/pages/ItemSearch";
import { ItemSearchDetails } from "./pages/Inventory/pages/ItemSearch/ItemSearchDetails";
import { PinSignIn } from "./pages/PinSignIn";
import { Profile } from "./pages/Profile";
import { Report } from "./pages/Report";
import { SalesTaxReport } from "./pages/Report/pages/SalesTaxReport";
import { DuplicateItems } from "./pages/Report/pages/DuplicateItems";
import { DynamicReport } from "./pages/Report/pages/DynamicReport";
import { Orders } from "./pages/Report/pages/Orders";
import { OrderDetails } from "./pages/Report/pages/Orders/OrderDetails";
import { Settings } from "./pages/Settings";
import { Stores } from "./pages/Settings/pages/Stores";
import { Users } from "./pages/Settings/pages/Users";
import { Roles } from "./pages/Settings/pages/Roles";
import { RegisterUser } from "./pages/Settings/pages/Users/RegisterUser";
import { SignIn } from "./pages/SignIn";
import { ForgotPassword } from "./pages/SignIn/ForgotPassword";
import { RegisterRole } from "./pages/Settings/pages/Roles/RegisterRole";
import { RegisterStore } from "./pages/Settings/pages/Stores/RegisterStore";

export default function AuthRoutes() {
  const { user, initialLoading, isLocked } = useAuth();

  function ProtectedRoute({ isAllowed, redirectPath }: any) {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }

    return <Outlet />;
  }

  return !initialLoading ? (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            isAllowed={!isLocked && !user?.user?.name}
            redirectPath={"/"}
          />
        }
      >
        <Route path="/signin" element={<SignIn />} />
      </Route>

      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route
        element={
          <ProtectedRoute
            isAllowed={isLocked && !user?.user}
            redirectPath={"/signin"}
          />
        }
      >
        <Route path="/pinSignIn" element={<PinSignIn />} />
      </Route>

      {/* allowed routes for logged users */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={user.user && !!user.user.name}
            redirectPath={isLocked ? "/pinSignIn" : "/signin"}
          />
        }
      >
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />

          {/* allowed only with specific permissions */}
          {/* Inventory Routes */}
          <Route
            element={
              <ProtectedRoute
                isAllowed={user?.user?.permissions?.includes(
                  permissions?.inventory?.itemSearch.view,
                )}
                redirectPath={"/"}
              />
            }
          >
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/inventory/item-search" element={<ItemSearch />} />
            <Route
              path="/inventory/item-search/:itemId"
              element={<ItemSearchDetails />}
            />
          </Route>

          {/* Duplicated Items Routes */}
          <Route
            element={
              <ProtectedRoute
                isAllowed={user?.user?.permissions?.includes(
                  permissions?.report?.duplicatedItems?.view,
                )}
                redirectPath={"/"}
              />
            }
          >
            <Route
              path="/report/duplicate-items/*"
              element={<DuplicateItems />}
            />
          </Route>

          {/* Orders Routes */}
          <Route
            element={
              <ProtectedRoute
                isAllowed={user?.user?.permissions?.includes(
                  permissions?.report?.orders?.view,
                )}
                redirectPath={"/"}
              />
            }
          >
            <Route path="/report/orders" element={<Orders />} />
            <Route path="/report/orders/:orderId" element={<OrderDetails />} />
          </Route>

          {/* Users Routes */}
          <Route
            element={
              <ProtectedRoute
                isAllowed={user?.user?.permissions?.includes(
                  permissions?.settings?.user,
                )}
                redirectPath={"/"}
              />
            }
          >
            <Route path="/settings/users" element={<Users />} />
            <Route path="/settings/users/create" element={<RegisterUser />} />
            <Route path="/settings/users/:userId" element={<RegisterUser />} />
          </Route>

          {/* Stores Routes */}
          <Route
            element={
              <ProtectedRoute
                isAllowed={user?.user?.permissions?.includes(
                  permissions?.settings?.stores,
                )}
                redirectPath={"/"}
              />
            }
          >
            <Route path="/settings/stores/" element={<Stores />} />
            <Route path="/settings/stores/create" element={<RegisterStore />} />
            <Route
              path="/settings/stores/:storeId"
              element={<RegisterStore />}
            />
          </Route>

          {/* Roles Routes */}
          <Route
            element={
              <ProtectedRoute
                isAllowed={user?.user?.permissions?.includes(
                  permissions?.settings?.roles,
                )}
                redirectPath={"/"}
              />
            }
          >
            <Route path="/settings/roles/" element={<Roles />} />
            <Route path="/settings/roles/create" element={<RegisterRole />} />
            <Route path="/settings/roles/:roleId" element={<RegisterRole />} />
          </Route>

          <Route
            element={
              <ProtectedRoute
                isAllowed={user?.user?.permissions?.includes(
                  permissions?.menu.report,
                )}
                redirectPath={"/"}
              />
            }
          >
            <Route path="/report" element={<Report />} />

            <Route
              element={
                <ProtectedRoute
                  isAllowed={user?.user?.permissions?.includes(
                    permissions?.report.explore.view,
                  )}
                  redirectPath={"/"}
                />
              }
            >
              <Route path="/report/dynamicReport" element={<DynamicReport />} />
            </Route>

            <Route
              element={
                <ProtectedRoute
                  isAllowed={user?.user?.permissions?.includes(
                    permissions?.report.exploreAccountingSales.view,
                  )}
                  redirectPath={"/"}
                />
              }
            >
              <Route
                path="/report/salesTaxReport"
                element={<SalesTaxReport />}
              />
            </Route>
          </Route>

          <Route
            element={
              <ProtectedRoute
                isAllowed={user?.user?.permissions?.includes(
                  permissions?.menu.settings,
                )}
                redirectPath={"/"}
              />
            }
          >
            <Route path="/settings" element={<Settings />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  ) : (
    <Routes>
      <Route path="/pinSignIn" element={<PinSignIn />} />
    </Routes>
  );
}
