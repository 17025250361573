import { FormEvent, useEffect, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import ReactLoading from "react-loading";
import PackageJson from "../../../package.json";
import Logo from "../../assets/images/white-logo.svg";
import { useAuth } from "../../hooks/auth";

import * as Yup from "yup";

import * as S from "./styles";

export function PinSignIn() {
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  const { authError, pinSignIn, isLoading, signOut } = useAuth();

  async function handlePinSignIn(event: FormEvent) {
    try {
      event.preventDefault();

      setError("");

      await pinSignIn(pin);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setError(err.message);
      }
    }
  }

  const handleLogout = () => {
    signOut();
  };

  useEffect(() => {
    const shopStoraged = localStorage.getItem("@Incycle:shopSelected");
    const parsedShopStoraged = shopStoraged ? JSON.parse(shopStoraged) : "";

    !parsedShopStoraged && signOut();
  }, []);

  return (
    <S.Container>
      <S.Content autoComplete="off" onSubmit={handlePinSignIn}>
        <img src={Logo} alt="logo" />

        <S.Title>Employee verification</S.Title>
        <S.InputContainer className="container" filled={!!pin}>
          <IoIosLock />
          <S.Input
            name="pin"
            type="password"
            autoComplete="one-time-code"
            autoFocus
            onChange={(value) => {
              setError("");
              setPin(value.target.value);
            }}
          />
        </S.InputContainer>
        {(error.includes("4 digits") || !!authError) && (
          <div className="error_div">
            <FaExclamationTriangle />
            <label>{error || authError}</label>
          </div>
        )}

        <S.PinSignInButton disabled={isLoading}>
          {isLoading ? (
            <ReactLoading
              height={25}
              width={25}
              type={"spin"}
              color="#ffffff"
            />
          ) : (
            "Sign In"
          )}
        </S.PinSignInButton>
      </S.Content>

      <S.LogoutContainer>
        <span>Or</span>
        <button onClick={handleLogout}>Logout</button>
      </S.LogoutContainer>

      <S.VersionContainer>
        <p>Version {PackageJson.version}</p>
      </S.VersionContainer>
    </S.Container>
  );
}
