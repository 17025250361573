import * as S from "./styles";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { FiSearch } from "react-icons/fi";
import { useTheme } from "styled-components";
import { ReactNode } from "react";
import { Button } from "../Button";
import FilterIcon from "../../../assets/icons/filter-icon.svg";

interface SearchInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onPressEnter?: () => void;
  filterDropdown?: {
    selectInput: ReactNode;
    onFilterClick: () => void;
    onResetClick: () => void;
    disabled?: boolean;
  };
}

export function SearchInput({
  filterDropdown,
  onPressEnter,
  ...rest
}: SearchInputProps) {
  const { newColors } = useTheme();

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && onPressEnter) {
      onPressEnter();
    }
  }

  return (
    <S.Container>
      <FiSearch size={24} color={newColors["gray-900"]} />
      <input
        type="text"
        placeholder="Search"
        onKeyDown={handleKeyDown}
        {...rest}
      />
      {filterDropdown && (
        <DropdownMenu.Root modal={false}>
          <DropdownMenu.Trigger asChild disabled={filterDropdown.disabled}>
            <S.IconButton>
              <img src={FilterIcon} alt="Filter icon" />
            </S.IconButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <S.DropdownContent sideOffset={32}>
              <S.Title>Filter by</S.Title>

              {filterDropdown.selectInput}

              <S.ButtonContainer>
                <Button
                  title="Filter"
                  style={{ width: "100%" }}
                  onClick={filterDropdown.onFilterClick}
                />
                <Button
                  title="Reset filters"
                  variant="ghost"
                  style={{ width: "100%" }}
                  onClick={filterDropdown.onResetClick}
                />
              </S.ButtonContainer>
            </S.DropdownContent>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      )}
    </S.Container>
  );
}
