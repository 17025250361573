import { ButtonHTMLAttributes } from "react";
import NoDataImage from "../../assets/images/no-data.png";  
import * as S from "./styles";

interface NotFoundProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  content: string;
  buttonTitle?: string;
  showImage?: boolean;  
}

export function NotFound({ title, content, buttonTitle, showImage = true, ...rest }: NotFoundProps) {
  return (
    <S.Container>
      {showImage && <img src={NoDataImage} alt="No data img" />} 
      <h3>{title ? title : "No data found"}</h3>
      <span>{content}</span>

      {buttonTitle && <button {...rest}>{buttonTitle}</button>}
    </S.Container>
  );
}
