import styled from "styled-components";

export const ModalFormContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const ModalAlign = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.75rem;
  align-items: start;

  div.marginSize {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
`;

export const InputTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors.black};

  .required {
    color: ${(props) => props.theme.colors["red-900"]};
    margin-left: 0.375rem;
  }
`;

export const SelectTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors["black-925"]};
`;
