import styled from "styled-components";

export const StoresContainer = styled.main`
  padding: 2rem;
`;

export const StoresFiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin: 2rem 0 2.75rem;
  gap: 1.5rem;

  input {
    width: 22rem;
  }

  div.filters_align {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    gap: 1.5rem;
  }
`;

export const Content = styled.div``;
