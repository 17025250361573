import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Button } from "../../../../components/Button";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { Pagination } from "../../../../components/Pagination";
import { SearchInput } from "../../../../components/SearchInput";
import { SelectInput } from "../../../../components/SelectInput";
import { OrdersList } from "./components/OrdersList";

import { AxiosError } from "axios";
import { useSearchParams } from "react-router-dom";
import { OrderListParams } from "../../../../@types/Orders";
import { OrderStatus } from "../../../../enums/Orders";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";

export function Orders() {
  const updatedParams = new URLSearchParams();

  const [ordersSearchParams, setOrdersSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [ordersList, setOrdersList] = useState<OrderListParams>({
    orders: [],
    page: ordersSearchParams.get("page") ?? "1",
    limit: ordersSearchParams.get("limit") ?? "5",
    totalPages: ordersSearchParams.get("totalPages") ?? "0",

    status: ordersSearchParams.get("status") ?? "",
    filter: ordersSearchParams.get("filter") ?? "",
    search: ordersSearchParams.get("search") ?? "",
  });

  const { orders, page, limit } = ordersList;

  const filterDisplayName = {
    orderReferenceNumber: "Order ID",
    storeOrderID: "Site Order ID",
    billingName: "Customer Name",
    buyerEmailAddress: "Customer Email",
  };

  const fetchOrders = async () => {
    setLoading(true);

    try {
      const { data } = await wrapperRequests(
        routesURL.report.orders.getOrders,
        "GET",
        {
          params: {
            page,
            limit,
            processStatus: ordersList.status
              ? [ordersList.status]
              : [
                  "ERROR",
                  "SUCCESS",
                  "UNPROCESSABLE",
                  "CANCELLED",
                  "PARTIALLY_PROCESSED",
                ],
            [ordersList.filter]: ordersList.search
              ? ordersList.search
              : undefined,
          },
        },
      );

      setOrdersList((state) => ({
        ...state,
        orders: data.orders,
        totalPages: data.totalPages,
      }));
    } catch (error) {
      if (error instanceof AxiosError) {
        throw Error(error.response?.data.message);
      }
      throw Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const updateFiltersValue = (key: string, value: string) => {
    setOrdersList((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const updateSearchParams = () => {
    ordersList.search && updatedParams.set("search", ordersList.search);
    ordersList.status && updatedParams.set("status", ordersList.status);
    ordersList.filter && updatedParams.set("filter", ordersList.filter);

    updatedParams.set("page", "1");
    updatedParams.set("limit", String(limit));

    setOrdersSearchParams(updatedParams);
  };

  const resetParams = () => {
    setOrdersSearchParams({
      limit: "5",
      page: "1",
    });
  };

  const updateRows = (value: number) => {
    setOrdersSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setOrdersSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <S.OrdersSyncErrorContainer>
      <PageHeader
        title="Orders"
        description="See all processed orders"
        hasButtons
        refreshFunction={resetParams}
      />

      <S.OrdersSyncErrorFiltersContainer>
        <div className="filters_align">
          <SelectInput
            title="Select a status"
            variant="small"
            placeholder="Select options"
            value={ordersList.status}
            options={[
              { id: "1", value: OrderStatus.SUCCESS, label: "Success" },
              { id: "2", value: OrderStatus.ERROR, label: "Error" },
              {
                id: "3",
                value: OrderStatus.UNPROCESSABLE,
                label: "Unprocessable",
              },
              {
                id: "4",
                value: OrderStatus.PARTIALLY_PROCESSED,
                label: "Partially Processed",
              },
              {
                id: "5",
                value: OrderStatus.CANCELLED,
                label: "Canceled",
              },
            ]}
            onChange={(event) => {
              updateFiltersValue("status", event.target.value);
            }}
          />

          <SelectInput
            title="Select a filter"
            variant="small"
            placeholder="Select options"
            value={ordersList.filter}
            options={[
              {
                id: "1",
                value: "orderReferenceNumber",
                label: "Order ID",
              },
              {
                id: "2",
                value: "storeOrderID",
                label: "Site Order ID",
              },
              { id: "3", value: "billingName", label: "Customer Name" },
              { id: "4", value: "buyerEmailAddress", label: "Customer Email" },
            ]}
            onChange={(event) => {
              updateFiltersValue("filter", event.target.value);
            }}
          />

          <SearchInput
            title="Search by identifier"
            searchIcon
            placeholder={`Search by: ${
              filterDisplayName[
                ordersList.filter as keyof typeof filterDisplayName
              ] || ""
            }`}
            value={ordersList.search}
            onPressEnter={() => updateSearchParams()}
            onChange={(event) => {
              updateFiltersValue("search", event.target.value);
            }}
          />

          <Button
            disabled={loading}
            onClick={() => {
              updateSearchParams();
            }}
          >
            {loading ? (
              <ReactLoading height={24} width={24} type={"spin"} color="#fff" />
            ) : (
              "Search"
            )}
          </Button>
        </div>
      </S.OrdersSyncErrorFiltersContainer>

      <>
        {!!orders && <OrdersList data={orders} loading={loading} />}
        <Pagination
          placeholder={String(ordersList.limit)}
          selectPage={updatePage}
          page={Number(ordersList.page)}
          selectRowsPerPage={updateRows}
          totalPages={Number(ordersList.totalPages)}
        />
      </>
    </S.OrdersSyncErrorContainer>
  );
}
