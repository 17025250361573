import styled, { css } from "styled-components";

interface SwitchContainerProps {
  width?: string;
}

export const SwitchContainer = styled.div<SwitchContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  span {
    line-height: 1.3rem;
    font-size: ${(props) => props.theme.fontSize.XXXS};
    color: ${(props) => props.theme.colors["black-925"]};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
  }

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: none;
    border-radius: ${(props) => props.theme.borderWidth.Heavy};
    background-color: ${(props) => props.theme.colors["gray-300"]};
    padding: 0.625rem 0.5rem;
    color: ${(props) => props.theme.colors["black-925"]};

    ${(props) =>
      props.width &&
      css`
        width: ${props.width};
      `};
  }
`;
