import styled, { css } from "styled-components";
import { OrderItemProcessStatus } from "../../../../../../enums/Orders";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  margin-top: 1.5rem;
`;

export const SumaryContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  gap: 1rem;

  div {
    display: flex;
    align-items: center;

    height: 1.5rem;
  }
`;

export const SumaryDataTitle = styled.p`
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  color: ${(props) => props.theme.newColors["gray-900"]};
  width: 12rem;
`;

export const SumaryData = styled.div`
  color: ${(props) => props.theme.newColors["black-900"]};
`;

export const Separator = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;

export const ShipmentsContainer = styled.div`
  margin: 1rem 0;
`;

export const HorizontalContainer = styled.div`
  display: flex;

  justify-content: space-between;
`;

interface ShipStatus {
  variant: string;
}

export const ShipContainer = styled.div<ShipStatus>`
  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.colors["green-150"]};
  color: ${(props) => props.theme.colors["green-800"]};

  height: 2rem;
  padding: 1rem;
  gap: 0.5rem;

  ${(props) =>
    props.variant !== "SHIPPED" &&
    css`
      background-color: ${(props) => props.theme.colors["red-300"]};
      color: ${(props) => props.theme.colors["gray-900"]};
    `}
`;

export const ShipmentsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 1.5rem;
  margin-top: 1.5rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1.5rem;
`;

export const ProductListsTitle = styled.h3`
  color: ${(props) => props.theme.colors["gray-850"]};
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  margin-bottom: 1.5rem;
`;

export const NumberOfProducts = styled.span`
  color: ${(props) => props.theme.colors["gray-850"]};
`;

export const TableContainer = styled.div`
  max-height: 28rem;
  overflow: auto;
  overflow-x: hidden;

  margin-top: 1rem;

  table {
    padding-right: 0.5rem;
  }

  thead {
    position: sticky;
    top: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors["gray-700"]};
  }
`;

interface StatusContainerProps {
  variant: keyof typeof OrderItemProcessStatus | "Completed" | "otherwise";
}

export const StatusContainer = styled.div<StatusContainerProps>`
  display: inline-block;
  padding: 0 0.75rem;
  border-radius: ${(props) => props.theme.borderRadius.XXLG};

  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;

  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors["red-425"]};

  ${(props) =>
    (props.variant === "CANCELLED" || props.variant === "CANCELED") &&
    css`
      background-color: ${(props) => props.theme.colors["gray-700"]};
      color: ${(props) => props.theme.colors["black-925"]};
    `};

  ${(props) =>
    props.variant === "ERROR" &&
    css`
      background-color: ${(props) => props.theme.colors["red-475"]};
      color: ${(props) => props.theme.colors["black-925"]};
    `};

  ${(props) =>
    props.variant === "NEW" &&
    css`
      background-color: ${(props) => props.theme.colors["cyan-200"]};
      color: ${(props) => props.theme.colors["black-925"]};
    `};

  ${(props) =>
    props.variant === "otherwise" &&
    css`
      color: ${(props) => props.theme.colors["black-925"]};
      background-color: ${(props) => props.theme.colors["gray-600"]};
    `};

  ${(props) =>
    props.variant === "PENDING" &&
    css`
      background-color: ${(props) => props.theme.colors["golden-500"]};
      color: ${(props) => props.theme.colors["black-925"]};
    `};

  ${(props) =>
    props.variant === "PICKED" &&
    css`
      background-color: ${(props) => props.theme.colors["green-650"]};
      color: ${(props) => props.theme.colors["black-925"]};
    `};

  ${(props) =>
    props.variant === "PICKING" &&
    css`
      background-color: ${(props) => props.theme.colors["blue-675"]};
    `};

  ${(props) =>
    props.variant === "SHIPPED" &&
    css`
      background-color: ${(props) => props.theme.colors["green-800"]};
    `};

  ${(props) =>
    props.variant === "SUCCESS" &&
    css`
      background-color: ${(props) => props.theme.colors["green-550"]};
      color: ${(props) => props.theme.colors["black-925"]};
    `};
`;

export const ActivityContainer = styled.div`
  display: flex;
`;

export const NotesContainerAlign = styled.div`
  display: flex;
`;

export const NotesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 2.5rem;
`;

export const ActivityTextArea = styled.textarea`
  width: 100%;
  height: 15rem;

  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  border-color: ${(props) => props.theme.colors["gray-400"]};
  border-radius: 0.5rem;
  color: ${(props) => props.theme.colors["black-925"]};

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors["gray-700"]};
  }

  &:disabled {
    opacity: 1;
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export const ActivityAlign = styled.div`
  flex: 1;
`;

export const ActivityContent = styled.div`
  max-height: 15rem;

  margin-top: 0.5rem;
  margin-bottom: 1.625rem;

  overflow: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors["gray-700"]};
  }
`;

export const ActivityReport = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;
  margin-right: 1rem;
`;

export const ActivityDate = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  color: ${(props) => props.theme.colors["gray-850"]};

  span {
    color: ${(props) => props.theme.colors["gray-850"]};
  }
`;

export const BillingContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

export const BillingTextContainer = styled.div`
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;

export const BillingAlignContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const BillingTitleContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const BillingTitle = styled.h4`
  font-size: ${(props) => props.theme.fontSize.XXS};
  font-weight: ${(props) => props.theme.fontWeight.Medium};
  color: ${(props) => props.theme.colors["gray-850"]};
`;

export const BillingText = styled.p`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  color: ${(props) => props.theme.colors["gray-850"]};
`;

export const PriceContainer = styled.section`
  margin-top: 1.5rem;
`;

export const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  margin-top: 1.5rem;
`;

interface PriceProps {
  variant?: "discount" | "total";
}

export const PriceWrapper = styled.div<PriceProps>`
  display: flex;
  align-items: center;
  gap: 1.75rem;

  font-size: ${(props) => props.theme.fontSize.XXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  color: ${(props) => props.theme.colors["black-925"]};

  & > p:first-child {
    width: 4.5rem;
  }

  span {
    color: ${(props) => props.theme.colors["gray-850"]};
  }

  ${(props) => {
    if (props.variant === "discount") {
      return css`
        & > p:last-child {
          color: ${(props) => props.theme.newColors["red-500"]};
        }
      `;
    }

    if (props.variant === "total") {
      return css`
        & > p:last-child {
          color: ${(props) => props.theme.colors["green-800"]};
        }
      `;
    }
  }}
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
`;
