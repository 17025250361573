import { useState } from "react";
import { useTheme } from "styled-components";
import { useAuth } from "../../hooks/auth";
import { wrapperRequests } from "../../services/api";
import { Input } from "../../components/New/Input";
import { Button } from "../../components/New/Button";
import * as S from "./styles";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ToastPosition, toast } from "react-toastify";
import * as z from "zod";
import { routesURL } from "../../services/routesUrl";

const schema = z.object({
  pin: z.union([
    z.string().length(4, { message: "PIN must be 4 digits or more" }),
    z.string().min(4, { message: "PIN must be 4 digits or more" }),
  ]),
});

type UpdateUserSchema = z.infer<typeof schema>;

export function Profile() {
  const { colors } = useTheme();
  const { user } = useAuth();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UpdateUserSchema>({
    resolver: zodResolver(schema),
    reValidateMode: "onSubmit",
  });

  const [loadings, setLoadings] = useState({
    updatePIN: false,
  });
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");

  const separateName = user.user.name.split(" ");

  const toastify = (message: string, position: ToastPosition) => {
    toast.success(message, {
      position,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateLoadingState = (key: string, value: boolean) => {
    setLoadings((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const handleUpdatePIN = async (data: UpdateUserSchema) => {
    updateLoadingState("updatePIN", true);
    try {
      await wrapperRequests(
        routesURL.settings.users.editUser(user.user.id),
        "PUT",
        { data },
      );

      toastify("🚴‍♂️ User edited successfully!", "top-center");
      setUpdateErrorMessage("");
    } catch (error: any) {
      console.error("Erro ao atualizar PIN:", error);
      setUpdateErrorMessage(error.response?.data?.message || "Duplicated PIN");
      throw Error(String(error));
    } finally {
      updateLoadingState("updatePIN", false);
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.PersonalInformation>
          <h2>Personal information</h2>
          <S.UserDataContainer>
            <Input
              title="Name"
              type="text"
              defaultValue={separateName[0]}
              disabled
            />
            <Input
              title="Last name"
              type="text"
              defaultValue={separateName[1]}
              disabled
            />
            <Input
              title="Email"
              defaultValue={user.user.email}
              type="email"
              disabled
            />
            <Input title="Birth date" disabled />
            <Input title="Phone number" disabled />
          </S.UserDataContainer>
        </S.PersonalInformation>

        <S.LoginSecurity>
          <h2>Login and security</h2>
          <S.UpdatePasswordContainer>
            <Input title="Current password" type="password" disabled />

            <Input title="New password" type="password" disabled />

            <Input
              title="Pin"
              type="password"
              errorMessage={errors.pin?.message || updateErrorMessage}
              {...register("pin")}
            />

            <S.WrapperChangePassword hasError={!!updateErrorMessage}>
              <form
                className="form_update_pin"
                onSubmit={handleSubmit(handleUpdatePIN)}
              >
                <Button
                  title="Save"
                  onClick={handleSubmit(handleUpdatePIN)}
                  isLoading={loadings.updatePIN}
                  disabled={loadings.updatePIN}
                >
                  Salvar
                </Button>
              </form>
            </S.WrapperChangePassword>
          </S.UpdatePasswordContainer>
        </S.LoginSecurity>
      </S.Content>
    </S.Container>
  );
}
