import styled, { css } from "styled-components";

type ContainerProps = {
  isResponsive: boolean;
};

export const Container = styled.section<ContainerProps>`
  width: 100%;
  width: calc(100vw - 7rem - var(--sideBarWidth));
  overflow-x: auto;
  overflow-y: hidden;

  ${(props) =>
    props.isResponsive &&
    css`
      width: calc(100vw - 7rem - var(--sideBarWidthResponsive));
    `};

  table {
    width: 100%;
    background-color: ${(props) => props.theme.newColors["white-500"]};
    border: 1px solid ${(props) => props.theme.newColors["gray-500"]};
    border-radius: 4px;
    border-spacing: 0;
    border-bottom: none;

    th,
    td {
      text-align: left;
      font-size: ${(props) => props.theme.fontSize.XXXS};
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      color: ${(props) => props.theme.newColors["gray-900"]};
      padding: 0.25rem 0;
      padding-left: 1rem;

      border-bottom: 1px solid ${(props) => props.theme.newColors["gray-500"]};
    }

    th:first-child,
    td:first-child {
      border-right: 1px solid ${(props) => props.theme.newColors["gray-500"]};
    }

    th:last-child,
    td:last-child {
      padding-right: 1rem;
    }

    th:last-child,
    td:last-child {
      border-right: none;
    }

    td {
      font-weight: ${(props) => props.theme.fontWeight.Regular};
    }

    th {
      padding-left: 0;
    }
  }
`;

export const ThStyled = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  padding-left: 1rem;
`;
