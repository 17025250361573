import ReactLoading from "react-loading";
import { colors } from "../../../styles/themes/default/foundations/colors";
import * as S from "./styles";

export function Loading() {
  return (
    <S.Container>
      <ReactLoading
        height={48}
        width={48}
        type={"spin"}
        color={colors["gray-800"]}
      />
    </S.Container>
  );
}
