import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { UserListParams, UserTypes } from "../../../../@types/User";
import { SelectInput } from "../../../../components/SelectInput";
import { useResponsive } from "../../../../hooks/useResponsive";

import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import { ToastPosition, toast } from "react-toastify";
import { UsersPermissionsContext } from "../../../../contexts/UsersPermissions";
import { useContextSelector } from "use-context-selector";
import * as S from "./styles";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { Table } from "../../../../components/New/Table";
import { ColumnDef } from "@tanstack/react-table";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useTheme } from "styled-components";
import { SearchInput } from "../../../../components/New/SearchInput";
import { Modal } from "../../../../components/New/Modal";
import { DeleteModal } from "../../../../components/New/Modal/components/DeleteModal";
import { DeleteIcon } from "../../../../components/New/Modal/components/DeleteIcon";
import { Button } from "../../../../components/New/Button";
import { Loading } from "../../../../components/New/Loading";

interface Toastify {
  success: string;
  error: string;
}

export function Users() {
  const { getAllRoles, allRoles } = useContextSelector(
    UsersPermissionsContext,
    (context) => {
      return context;
    },
  );

  const updatedParams = new URLSearchParams();

  const navigate = useNavigate();

  const [userSearchParams, setUserSearchParams] = useSearchParams();
  const [users, setUsers] = useState<UserTypes[]>([]);
  const [userListParams, setUserListParams] = useState<UserListParams>({
    name: userSearchParams.get("name") ?? "",
    page: userSearchParams.get("page") ?? "1",
    role: userSearchParams.get("role") ?? "",
    rowsPerPage: userSearchParams.get("limit") ?? "5",
    sortBy: userSearchParams.get("sortBy") ?? "",
    totalPages: userSearchParams.get("totalPages") ?? "0",
  });
  const [loading, setLoading] = useState(false);

  const { screenWidth } = useResponsive();
  const { colors } = useTheme();

  const getUsers = async () => {
    setLoading(true);
    try {
      const { page, rowsPerPage, role, name, sortBy } = userListParams;

      const { data } = await wrapperRequests(
        routesURL.settings.users.getAllUsers,
        "GET",
        {
          params: {
            page,
            limit: rowsPerPage,
            role,
            name,
            sortBy,
          },
        },
      );

      setUsers(data.content);
      setUserListParams((state) => ({
        ...state,
        totalPages: data.totalPages || "0",
      }));
    } catch (error) {
      throw new Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const resetParams = () => {
    setUserSearchParams({
      limit: "5",
      page: "1",
    });
  };

  const toastify = (
    type: keyof Toastify,
    message: string,
    position: ToastPosition,
  ) => {
    toast[type](message, {
      position,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  async function handleDeleteUser(id: string) {
    setLoading(true);
    try {
      await wrapperRequests(`/users/${id}`, "DELETE");

      setUsers((state) => state.filter((user) => user.id !== id));

      toastify("success", "🚴‍♂️ User deleted successfully!", "top-center");
    } catch (error) {
      toastify("error", "User delete error.", "top-center");
    } finally {
      setLoading(false);
    }
  }

  const updateSearchParams = () => {
    userListParams?.name && updatedParams.set("name", userListParams.name);
    userListParams?.role && updatedParams.set("role", userListParams.role);

    updatedParams.set("limit", String(userListParams.rowsPerPage));
    updatedParams.set("page", "1");

    setUserSearchParams(updatedParams);
  };

  const updateUserParams = (key: keyof UserListParams, value: string) => {
    setUserListParams((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const updateRows = (value: number) => {
    setUserSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setUserSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const updateSortBy = (value: string) => {
    setUserSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("sortBy", String(value));
      return updatedParams;
    });
  };

  function rolesNames(rolesQtd: number) {
    if (rolesQtd === 0) return "Custom";
    if (rolesQtd === 1) return;
    if (rolesQtd > 1) return "Roles +1";
  }

  const usersColumns: ColumnDef<UserTypes>[] = [
    {
      accessorKey: "name",
      header: "Name",
      size: 265,
      cell: (props) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 265,
      cell: (props) => <p>{props.getValue()}</p>,
    },
    {
      accessorFn: (row) => row?.roles[0]?.name || "Custom",
      header: "Roles",
      size: 265,
      cell: (props) => (
        <S.RolesContainer
          role={
            rolesNames(props.row.original.roles.length)
              ? rolesNames(props.row.original.roles.length)
              : String(props.row.original.roles.map((role) => role.name))
          }
        >
          <p>{props.getValue()}</p>
        </S.RolesContainer>
      ),
    },
    {
      accessorKey: "",
      size: 100,
      header: "Actions",
      cell: (props) => (
        <S.IconContainer>
          <MdOutlineModeEditOutline
            size={24}
            style={{ cursor: "pointer" }}
            title="Edit"
            onClick={() =>
              navigate(`/settings/users/${props.row.original.id}`, {
                state: { id: props.row.original.id },
              })
            }
          />

          <Modal
            customButtons
            modalContent={DeleteModal({
              message: `Are you sure you want to delete the user "${props.row.original.name}"?`,
              onClick: () => handleDeleteUser(props.row.original.id),
              isLoading: loading,
            })}
            title="User delete"
            width="21.25rem"
          >
            <DeleteIcon title="Delete" />
          </Modal>
        </S.IconContainer>
      ),
    },
  ];

  useEffect(() => {
    getUsers();
    getAllRoles();
  }, []);

  return (
    <S.Container>
      <PageHeader
        title="Users"
        description="See all users registered in the system"
        hasButtons
        refreshFunction={() => resetParams()}
        customButton
      >
        <Button
          title="New User"
          width="7.5rem"
          onClick={() => navigate("/settings/users/create")}
        />
      </PageHeader>

      <S.Content>
        <SearchInput
          disabled={loading}
          onPressEnter={() => updateSearchParams()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateUserParams("name", event.target.value);
          }}
          value={userListParams.name}
          filterDropdown={{
            disabled: loading,
            selectInput: (
              <SelectInput
                placeholder="Select an option"
                options={allRoles.map((role) => ({
                  id: role.id,
                  value: role.name,
                  label: role.name,
                }))}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  updateUserParams("role", event.target.value);
                }}
                value={userListParams.role}
                variant="resized"
              />
            ),
            onFilterClick: () => updateSearchParams(),
            onResetClick: () => updateUserParams("role", ""),
          }}
        />

        {loading && <Loading />}

        {users.length > 0 && !loading && (
          <>
            <Table
              columns={usersColumns}
              columnToFilter="name"
              data={users}
              key={users.length}
              style={{
                marginTop: "0.75rem",
                marginBottom: "2rem",
                width: "100%",
              }}
              pagination={{
                rowsPerPage: Number(userListParams.rowsPerPage),
                selectPage: updatePage,
                selectRowsPerPage: updateRows,
                page: Number(userListParams.page),
                totalPages: Number(userListParams.totalPages),
              }}
            />
          </>
        )}
      </S.Content>
    </S.Container>
  );
}
