import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const BreadcrumbContainer = styled.header`
  display: flex;
  align-items: center;

  flex-direction: row;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    color: ${(props) => props.theme.colors["gray-800"]};
  }
`;

interface TextLinkProps {
  end: boolean;
}

export const TextLink = styled(NavLink)<TextLinkProps>`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors["gray-800"]};
  text-decoration: none;
  text-transform: capitalize;

  ${(props) =>
    props.end
      ? css`
          color: ${(props) => props.theme.colors["blue-600"]};
        `
      : css`
          &:hover {
            color: ${(props) => props.theme.colors["blue-600"]};
            text-decoration-color: ${(props) => props.theme.colors["blue-600"]};
            text-decoration: underline;
            transition: all 0.2s;
          }
        `};
`;
