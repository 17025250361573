import { useState, useRef, useEffect, HTMLProps } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import * as S from "./styles";

interface SearchableSelectInputProps<T extends Record<string, any>>
  extends HTMLProps<HTMLInputElement> {
  initialValue: T;
  options: T[];
  onOptionSelect: (option: T) => void;
  placeholder?: string;
  title?: string;
  disabled?: boolean;
}

export function SearchableSelectInput<T extends Record<string, any>>({
  disabled,
  initialValue,
  options,
  onOptionSelect,
  placeholder = "Search...",
  title,
  ...rest
}: SearchableSelectInputProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(initialValue.name);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState(initialValue);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOptionClick = (option: T) => {
    setSearchValue(option.name);
    setIsOpen(false);
    onOptionSelect(option);
    setSelectedOption(option);
  };

  const handleOnInputClick = () => {
    if (inputRef.current) {
      setIsOpen(!isOpen);
      inputRef.current.select();
    }
  };

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) => {
        if (option.name) {
          return option.name.toLowerCase().includes(searchValue.toLowerCase());
        }
        return;
      }),
    );
  }, [searchValue, options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setSearchValue(selectedOption.name);
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedOption]);

  return (
    <>
      {title && <S.Title>{title}</S.Title>}
      <S.DropdownContainer
        ref={dropdownRef}
        disabled={disabled}
        title={selectedOption?.name}
      >
        <S.InputWrapper>
          <input
            type="text"
            value={searchValue}
            placeholder={placeholder}
            ref={inputRef}
            onClick={handleOnInputClick}
            onChange={(e) => setSearchValue(e.target.value)}
            disabled={disabled}
            {...rest}
          />
          <MdKeyboardArrowDown
            className="arrow-icon"
            onClick={() => !disabled && setIsOpen(!isOpen)}
          />
        </S.InputWrapper>

        {isOpen && filteredOptions.length > 0 && (
          <S.OptionsList>
            {filteredOptions.map((option) => (
              <S.OptionItem
                key={option.id}
                onClick={() => handleOptionClick(option)}
              >
                {option.name}
              </S.OptionItem>
            ))}
          </S.OptionsList>
        )}
        {isOpen && filteredOptions.length === 0 && (
          <S.NoOptionsMessage>No results found</S.NoOptionsMessage>
        )}
      </S.DropdownContainer>
    </>
  );
}
