import styled from "styled-components";

export const ReportContainer = styled.main`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;

  padding: 2rem;
`;
