import { BsCalendarMonth } from "react-icons/bs";
import ReactInputMask from "react-input-mask";
import styled, { css } from "styled-components";
import { defaultTheme } from "../../../../../../styles/themes/default/default";

interface InputActivityState {
  isDisabled: boolean;
}

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  margin-bottom: 0.75rem;
  margin-left: auto;


  svg {
    cursor: pointer;
    margin-right: 0.5rem;
    color: ${(props) => props.theme.colors["gray-800"]};
    align-items: center;
  }

  span {
    width: 60%;
    color: ${(props) => props.theme.colors["gray-800"]};
    text-transform: capitalize;
  }

  input[type="text"] {
    font-size: ${(props) => props.theme.fontSize.XXS};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    color: ${(props) => props.theme.colors["black-925"]};

    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors["gray-400"]};

    background-color: ${(props) => props.theme.colors.white};
  }
`;

export const InputDateContainer = styled.div<InputActivityState>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 0.5rem;
  height: 2rem;
  width: 15rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  

  position: relative;

  & > button {
    border: none;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:disabled {
      cursor: not-allowed;
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: transparent;
    `}
`;

export const DateInput = styled(ReactInputMask).attrs({
  style: {
    width: "15rem",
    backgroundColor: "transparent",
    border: "none",
    height: "100%",
  },
})`
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-size: ${(props) => props.theme.fontSize.XXS};
  color: ${(props) => props.theme.colors["black-925"]};
`;

export const CalendarIcon = styled(BsCalendarMonth).attrs({
  size: "20px",
  color: defaultTheme.colors["gray-900"],
})``;

