import { ReactNode } from "react";
import * as S from "./styles";

interface CardButtonProps {
  title: string;
  description: string;
  href: string;
  children: ReactNode;
}

export function CardLinkButton({
  title,
  description,
  href,
  children,
}: CardButtonProps) {
  return (
    <S.CardButtonContainer to={href}>
      <S.IconCircle>{children}</S.IconCircle>
      <S.CardButtonInfoContainer>
          <S.CardButtonTitle>{title}</S.CardButtonTitle>
          <S.CardButtonText>{description}</S.CardButtonText>
        <S.View>View</S.View>
      </S.CardButtonInfoContainer>
    </S.CardButtonContainer>
  );
}
