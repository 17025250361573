import { GoLocation } from "react-icons/go";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

interface ResponsiveProps {
  responsiveLayout: boolean;
}

export const MenuContainer = styled.main<ResponsiveProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  background-color: ${(props) => props.theme.colors.black};
  width: 15rem;
  min-height: 100vh;

  padding: 0 1.5rem;

  z-index: 1000;

  img {
    height: 2.65rem;
    width: 7.125rem;

    margin: 2rem 0 1.4rem;

    &:hover {
      cursor: pointer;
    }
  }

  ${(props) =>
    props.responsiveLayout &&
    css`
      width: 4.75rem;
      padding: 0 1rem;
    `};
`;

export const MenuContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 0.5rem;

  height: 100vh;

  padding-bottom: 2rem;

  &&.responsive_layout {
    width: 100%;
    margin-top: -0.35rem;
  }
`;

export const CentralStoreButton = styled(NavLink)`
  display: flex;
  align-items: center;
  cursor: pointer;

  margin-bottom: -0.5rem;
  padding: 0.25rem 0.75rem;

  background-color: ${(props) => props.theme.colors["blue-600"]};
  border: 0;
  border-radius: ${(props) => props.theme.borderRadius.S};

  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  line-height: ${(props) => props.theme.lineHeight.Default};
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};

  width: 100%;
  min-height: 2rem;

  svg {
    margin-right: 0.5rem;
    color: ${(props) => props.theme.colors.white};
    flex-shrink: 0;
  }

  span {
    flex: 1;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors["blue-650"]};
    outline: 2px solid ${(props) => props.theme.colors["blue-500"]};
    transition: all 0.1s;
  }

  &&.store_button_responsive {
    justify-content: center;
    padding: 0;

    svg {
      margin: 0;
    }
  }
`;

export const LocationIcon = styled(GoLocation).attrs({
  size: "1rem",
})`
  margin-right: 0.5rem;

  &&.location_icon_responsive {
    margin-right: 0.5rem;
    position: relative;
  }

  flex-shrink: 0;
`;

export const MenuOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 0.5rem;

  width: 100%;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  border-bottom-width: ${(props) => props.theme.borderWidth.Hairline};
  border-bottom-color: ${(props) => props.theme.colors["gray-900"]};
  border-bottom-style: solid;
`;

export const MenuButton = styled(NavLink)`
  display: flex;
  align-items: center;

  margin: 0;
  height: 2rem;
  padding: 0.25rem 0.75rem;

  width: 100%;

  background-color: ${(props) => props.theme.colors.black};
  border: 0;
  border-radius: ${(props) => props.theme.borderRadius.S};

  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  line-height: ${(props) => props.theme.lineHeight.Default};
  text-decoration: none;
  color: ${(props) => props.theme.colors["white"]};

  svg {
    margin-right: 0.5rem;
  }

  transition:
    background-color 0.3s,
    color 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors["gray-900"]};
    color: ${(props) => props.theme.colors.white};
  }

  &.active {
    color: ${(props) => props.theme.colors["white"]};
    background-color: ${(props) => props.theme.colors["gray-850"]};
  }

  &&.menu_button_responsive {
    justify-content: center;
    height: 2rem;
    padding: 0;

    svg {
      margin: 0;
    }
  }
`;

export const LockContainer = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;

  margin: 0;
  height: 2rem;
  padding: 0.25rem 0.75rem;

  width: 100%;
  margin-top: auto;

  background-color: ${(props) => props.theme.colors["gray-850"]};
  border: 0;
  border-radius: ${(props) => props.theme.borderRadius.S};

  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  line-height: ${(props) => props.theme.lineHeight.Default};
  text-decoration: none;
  color: ${(props) => props.theme.colors["white"]};

  svg {
    margin-right: 0.5rem;
  }

  &&.button_responsive {
    justify-content: center;
    height: 2rem;
    padding: 0;

    svg {
      margin: 0;
    }
  }
`;

export const VersionContainer = styled.div<ResponsiveProps>`
  margin-bottom: ${(props) => (props.responsiveLayout ? "-1rem" : "-0.6rem")};

  p {
    text-align: center;
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    color: ${(props) => props.theme.colors["white"]};
    font-size: ${(props) => props.theme.fontSize.XXXS};
  }
`;

export const HideMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  padding: 0.25rem 0.75rem;
  width: 100%;
  height: 2rem;

  background-color: ${(props) => props.theme.colors["black"]};
  border: 1px solid ${(props) => props.theme.colors["white"]};
  border-radius: ${(props) => props.theme.borderRadius.S};

  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  line-height: ${(props) => props.theme.lineHeight.Default};
  text-decoration: none;
  color: ${(props) => props.theme.colors["white"]};

  svg {
    margin-right: 0.5rem;
    color: ${(props) => props.theme.colors["gray-100"]};
  }

  &&.button_responsive {
    justify-content: center;
    height: 2rem;
    padding: 0;

    svg {
      margin: 0;
    }
  }
`;
