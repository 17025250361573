import styled, { css } from "styled-components";

export const Container = styled.main`
  padding: 2rem;
`;

export const StoreNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StoreTypeContainer = styled.div`
  display: inline-block;

  height: 1.5rem;
  background-color: ${(props) => props.theme.colors["pink-600"]};
  border-radius: 200px;
  padding: 0rem 0.75rem;

  text-align: center;

  span {
    color: ${(props) => props.theme.newColors["black-900"]};
    font-size: ${(props) => props.theme.fontSize.XXXS};
    line-height: 1.5rem;
  }
`;

interface StatusContainerProps {
  variant: "Active" | "Inactive";
}

export const StatusContainer = styled.div<StatusContainerProps>`
  display: inline-block;

  height: 1.5rem;
  background-color: ${(props) => props.theme.newColors["green-300"]};
  border-radius: 200px;
  padding: 0rem 0.75rem;

  ${(props) =>
    props.variant === "Inactive" &&
    css`
      background-color: ${(props) => props.theme.newColors["gray-750"]};
    `};

  span {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    color: ${(props) => props.theme.colors["gray-850"]};
    line-height: 1.5rem;

    ${(props) =>
      props.variant === "Inactive" &&
      css`
        color: ${(props) => props.theme.newColors.white};
      `};
  }
`;

export const DefaultStoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
`;

export const UserRegisterHeader = styled.header`
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};

  h2 {
    font-size: ${(props) => props.theme.fontSize.MD};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.black};

    margin-bottom: 1.5rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  margin: 1.5rem 0;

  @media (max-width: 720px) {
    div.align_error {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  margin-top: 1.5rem;

  color: ${(props) => props.theme.colors["red-500"]};

  svg {
    margin-right: 5px;
  }
`;

export const UsersTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  color: ${(props) => props.theme.colors["black-925"]};
`;

interface InputRowContainerProps {
  isFirst?: boolean;
}

export const InputRowContainer = styled.div<InputRowContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  gap: 1.5rem;
  margin-bottom: 1rem;

  ${(props) =>
    props.isFirst &&
    css`
      margin-top: 1rem;
    `};
`;

export const PermissionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 0.5rem 0 0;
`;

export const UsersTitleDescription = styled.span`
  display: inline-block;

  font-size: ${(props) => props.theme.fontSize.XXXS};
  color: ${(props) => props.theme.colors["gray-800"]};

  margin-top: 0.5rem;
  max-width: 42rem;
`;

export const AlignTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorAlignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 1rem 0 0.5rem;
`;

export const SelectedStoresContainer = styled.div`
  div {
    display: flex;
  }

  p {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.black};

    margin: 1rem 0 0.25rem;
  }
`;

export const RolesAcess = styled.div`
  margin-top: 1rem;

  & > div:last-of-type {
    & > ul {
      list-style-type: none;
      margin-top: 0.5rem;
      max-width: 38rem;

      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;

      li {
        background-color: ${(props) => props.theme.colors["blue-200"]};
        padding: 0.5rem 1rem;
        border-radius: 24px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          flex: 1;
          font-size: ${(props) => props.theme.fontSize.XXXS};
          color: ${(props) => props.theme.newColors["gray-900"]};

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        svg {
          color: ${(props) => props.theme.newColors["gray-900"]};
          margin-left: 0.5rem;
        }
      }
    }
  }
`;

export const AccessGrantedContainer = styled.div`
  margin-top: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  gap: 0.75rem;

  justify-content: flex-end;
`;
