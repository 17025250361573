import { useEffect, useState } from "react";
import { Button } from "../../../../../../components/New/Button";
import { CircleCheckbox } from "../../../../../../components/New/CircleCheckbox";
import {
  ModalSubTitle,
  ModalText,
} from "../../../../../../components/New/Modal/styles";
import { IncreaseInput } from "../../../../../../components/New/IncreaseInput";
import { wrapperRequests } from "../../../../../../services/api";
import { routesURL } from "../../../../../../services/routesUrl";
import { useContextSelector } from "use-context-selector";
import { OrdersContext } from "../../../../../../contexts/OrdersContext";
import { Checkbox } from "../../../../../../components/New/Checkbox";
import { SelectInput } from "../../../../../../components/SelectInput";
import {
  EbayRefundReason,
  InternalReasonRefund,
  RefundCondition,
  RefundPercentage,
  RefundReason,
  StockLocationInitialValue,
} from "./constants";
import * as Dialog from "@radix-ui/react-dialog";
import {
  OrderRefundItem,
  RefundOrderInitialValue,
  RefundOrderSale,
  RefundSaleItemsReqBody,
} from "../../../../../../@types/Orders";
import { toastify } from "../../../../../../shared/Toastify";
import { useNavigate, useParams } from "react-router-dom";
import { newColors } from "../../../../../../styles/themes/default/New/foundations/colors";
import ReactLoading from "react-loading";
import { SearchableSelectInput } from "../../../../../../components/New/SearchableSelectInput";

import * as S from "./styles";
import {
  RefundTable,
  RefundTableRow,
} from "../../../../../../components/New/Table/RefundTable";
import { StoreToRefund } from "../../../../../../@types/Stores";

interface RefundModalProps {
  sourceMarketplace: "Incycle Shopify" | "Incycle Ebay" | "SBC";
}

export function RefundModal({ sourceMarketplace }: RefundModalProps) {
  const [checked, setChecked] = useState(false);
  const [initialEntireRefundItems, setInitialEntireRefundItems] = useState<
    RefundOrderSale[]
  >([]);
  const [entireRefundItems, setEntireRefundItems] = useState<RefundOrderSale[]>(
    [],
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [initialRefundItems, setInitialRefundItems] = useState<
    RefundOrderInitialValue[]
  >([]);

  const [refundItems, setRefundItems] = useState<RefundSaleItemsReqBody[]>([]);
  const [refundType, setRefundType] = useState<"entire" | "individual">(
    "entire",
  );
  const [allStores, setAllStores] = useState<StoreToRefund[]>([]);

  const { orderId } = useParams();
  const navigate = useNavigate();

  const { orderDetails } = useContextSelector(OrdersContext, (context) => {
    return context;
  });

  const reorganizedProductsToTable = initialRefundItems.flatMap(
    ({ saleID, items }) =>
      items.map((item) => ({
        ...item,
        saleID,
      })),
  );

  const isEbay = sourceMarketplace === "Incycle Ebay";
  const ebayProductsAlreadyHasQuantity = reorganizedProductsToTable.some(
    (item) => item.refundID,
  );

  const individualItemsQuantityElement = (product: OrderRefundItem) => {
    if (ebayProductsAlreadyHasQuantity) {
      return (
        <span>{`${product.quantityToRefund}/${product.quantityPurchased}`}</span>
      );
    } else {
      return (
        <IncreaseInput
          disabled={
            !refundItems.some(
              (items) => items.saleLineID === product.saleLineID,
            ) || loading
          }
          handleIncreaseValue={() =>
            handleChangeQuantity(
              product.saleLineID,
              "increase",
              product.quantityToRefund,
              refundItems.find((item) => item.saleLineID === product.saleLineID)
                ?.quantity || 1,
            )
          }
          handleDecreaseValue={() =>
            handleChangeQuantity(
              product.saleLineID,
              "decrease",
              product.quantityToRefund,
              refundItems.find((item) => item.saleLineID === product.saleLineID)
                ?.quantity || 1,
            )
          }
          maxValue={product.quantityToRefund}
          value={
            refundItems.find((item) => item.saleLineID === product.saleLineID)
              ?.quantity || 1
          }
        />
      );
    }
  };

  const getRefundProducts = async () => {
    const response = await wrapperRequests(
      routesURL.report.orders.getRefundSalesItems(orderDetails.id),
      "GET",
    );

    setInitialRefundItems(response.data);
    setInitialLoading(false);

    const transformArray = response.data.map(
      (sale: RefundOrderInitialValue) => ({
        saleID: sale.saleID,
        saleLines: sale.items.map((item) => ({
          internalReason: "",
          saleLineID: item.saleLineID,
          quantity: item.quantityToRefund,
          reason: isEbay ? "BUYER_CANCEL" : "customer",
          condition: "New",
          refundPercentage: "100%",
          shopID: "9",
          tenantName: "Incycle",
          saleID: sale.saleID,
          productID: item.productID,
        })),
      }),
    );

    setInitialEntireRefundItems(transformArray);
    setEntireRefundItems(transformArray);
  };

  const getAllStoresOptions = async () => {
    try {
      const response = await wrapperRequests(
        routesURL.settings.stores.getCustomAllStores([
          "name",
          "displayName",
          "code",
          "tenantName",
        ]),
        "GET",
      );

      setAllStores(response.data.content);
    } catch (error) {
      throw Error(String(error));
    }
  };

  const addOrRemoveItem = (product: OrderRefundItem) => {
    setRefundItems((prevProducts) => {
      const existingIndex = prevProducts.findIndex(
        (p) => p.saleLineID === product.saleLineID,
      );

      if (existingIndex === -1) {
        return [
          ...prevProducts,
          {
            internalReason: "",
            condition: "New",
            ...(ebayProductsAlreadyHasQuantity ? {} : { quantity: 1 }),
            reason: isEbay ? "BUYER_CANCEL" : "customer",
            refundPercentage: "100%",
            ...(isEbay ? { refundID: product.refundID } : {}),
            saleLineID: product.saleLineID,
            saleID: product.saleID,
            shopID: "9",
            tenantName: "Incycle",
            productID: product.productID,
          },
        ];
      } else {
        return prevProducts.filter((_, index) => index !== existingIndex);
      }
    });
  };

  const handleChangeQuantity = (
    saleLineID: string,
    type: "increase" | "decrease",
    maxValue: number,
    value: number,
  ) => {
    setRefundItems((prevProducts) =>
      prevProducts.map((product) => {
        if (product.saleLineID === saleLineID) {
          const newQuantity =
            type === "increase"
              ? value < maxValue
                ? value + 1
                : value
              : value > 1
                ? value - 1
                : value;
          return { ...product, quantity: newQuantity };
        }
        return product;
      }),
    );
  };

  const handleChangeSelectInputs = (
    saleLineID: string,
    type:
      | "reason"
      | "refundPercentage"
      | "condition"
      | "stockLocation"
      | "internalReason",
    newValue: string,
    secondValue?: string,
  ) => {
    setRefundItems((prevProducts) =>
      prevProducts.map((product) => {
        if (product.saleLineID === saleLineID) {
          if (!secondValue) {
            return {
              ...product,
              [type]: newValue,
            };
          }

          return {
            ...product,
            shopID: newValue,
            tenantName: secondValue,
          };
        }

        return product;
      }),
    );
  };

  const handleChangeSelectInputsEntireRefund = (
    type:
      | "reason"
      | "condition"
      | "refundPercentage"
      | "stockLocation"
      | "internalReason",
    newValue: string,
    secondValue?: string,
  ) => {
    setEntireRefundItems((prevProducts) =>
      prevProducts.map((product) => {
        const updatedSaleLines = product.saleLines.map((saleLine) => {
          if (!secondValue) {
            return {
              ...saleLine,
              [type]: newValue,
            };
          }

          return {
            ...saleLine,
            shopID: newValue,
            tenantName: secondValue,
          };
        });

        return {
          ...product,
          saleLines: updatedSaleLines,
        };
      }),
    );
  };

  const hasInternalReason = () => {
    if (refundItems.length > 0) {
      return refundItems.every((line) => line.internalReason.trim() !== "");
    }

    if (entireRefundItems.length > 0) {
      return entireRefundItems.every((sale) =>
        sale.saleLines.every((line) => line.internalReason.trim() !== ""),
      );
    }

    return false;
  };

  const handleSubmitRefund = async () => {
    if (!hasInternalReason()) {
      setErrorMessage("Returns Internal Reason Code is required");
      return;
    }

    setLoading(true);
    const grouped: { [key: string]: RefundSaleItemsReqBody[] } = {};

    refundItems.forEach((product) => {
      if (!grouped[product.saleID!]) {
        grouped[product.saleID!] = [];
      }

      grouped[product.saleID!].push({
        internalReason: product.internalReason,
        saleLineID: product.saleLineID,
        quantity: product.quantity,
        reason: product.reason,
        condition: product.condition,
        tenantName: product.tenantName,
        shopID: product.shopID,
        refundPercentage: product.refundPercentage,
        productID: product.productID,
      });
    });

    const reorganizedArray = Object.keys(grouped).map((saleID) => ({
      saleID,
      saleLines: grouped[saleID],
    }));

    const individualRefundBody = {
      sales: reorganizedArray,
    };

    const entireRefundBody = {
      sales: entireRefundItems,
    };

    try {
      await wrapperRequests(routesURL.report.orders.refund, "POST", {
        data: refundType === "entire" ? entireRefundBody : individualRefundBody,
      });

      toastify("success", "Refund completed", "top-center");
      navigate(`/report/orders/${orderId}`);
    } catch (error) {
      toastify("error", "Refund error, try again", "top-center");
    } finally {
      setLoading(false);
    }
  };

  const handleEbaySubmitRefund = async () => {
    if (!hasInternalReason()) {
      setErrorMessage("Returns Internal Reason Code is required");
      return;
    }

    setLoading(true);

    const transformEntireRefundItemsArray = entireRefundItems.flatMap(
      (item) => item.saleLines,
    );

    const individualRefundBody = {
      refunds: refundItems,
    };

    const entireRefundBody = {
      refunds: transformEntireRefundItemsArray,
    };

    try {
      await wrapperRequests(routesURL.report.orders.refund, "POST", {
        data: refundType === "entire" ? entireRefundBody : individualRefundBody,
      });

      toastify("success", "Refund completed", "top-center");
      navigate(`/report/orders/${orderId}`);
    } catch (error) {
      toastify("error", "Refund error, try again", "top-center");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRefundProducts();
    getAllStoresOptions();
  }, []);

  return (
    <S.ModalContainer isLoading={initialLoading}>
      {initialLoading && (
        <ReactLoading
          height={25}
          width={25}
          type={"spin"}
          color={newColors["gray-600"]}
        />
      )}

      {!initialLoading && allStores[0] && (
        <>
          <ModalSubTitle>What would you like to refund?</ModalSubTitle>
          <S.OptionsAlign>
            <CircleCheckbox
              label="Entire order"
              onClick={() => {
                setRefundType("entire");
                setRefundItems([]);
                setEntireRefundItems(initialEntireRefundItems);
                setErrorMessage("");
              }}
              checked={refundType === "entire"}
              disabled={loading}
            />

            <CircleCheckbox
              label="Individual items"
              onClick={() => {
                setRefundType("individual");
                setErrorMessage("");
              }}
              checked={refundType === "individual"}
              disabled={loading}
            />
          </S.OptionsAlign>

          {refundType === "entire" && (
            <>
              <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
                Select a reason for the refund
              </ModalSubTitle>

              <SelectInput
                options={isEbay ? EbayRefundReason : RefundReason}
                variant="resized"
                onChange={(e) =>
                  handleChangeSelectInputsEntireRefund("reason", e.target.value)
                }
                disabled={loading}
              />

              <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
                Select the store where the item is being returned
              </ModalSubTitle>

              <SearchableSelectInput
                initialValue={StockLocationInitialValue[0]}
                options={allStores}
                onOptionSelect={(e) => {
                  handleChangeSelectInputsEntireRefund(
                    "stockLocation",
                    e.code,
                    e.tenantName,
                  );
                }}
              />

              <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
                Condition of the returned item
              </ModalSubTitle>

              <SelectInput
                options={RefundCondition}
                variant="resized"
                onChange={(e) =>
                  handleChangeSelectInputsEntireRefund(
                    "condition",
                    e.target.value,
                  )
                }
                disabled={loading}
              />

              <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
                Returns Internal Reason Code
              </ModalSubTitle>

              <SelectInput
                placeholder="Select an option"
                options={InternalReasonRefund}
                onChange={(e) => {
                  handleChangeSelectInputsEntireRefund(
                    "internalReason",
                    e.target.value,
                  );
                  setErrorMessage("");
                }}
                disabled={loading}
                variant="resized"
                error={errorMessage}
              />

              <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
                Refund percentage %
              </ModalSubTitle>

              <SelectInput
                options={RefundPercentage}
                variant="resized"
                onChange={(e) =>
                  handleChangeSelectInputsEntireRefund(
                    "refundPercentage",
                    e.target.value,
                  )
                }
                disabled={loading}
              />
            </>
          )}

          {refundType === "individual" && (
            <>
              <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
                Items
              </ModalSubTitle>
              <ModalText>Select the items you would like to refund</ModalText>

              {!!initialRefundItems.length && (
                <RefundTable
                  headers={[
                    "Product",
                    "Qty",
                    "Reason",
                    "RIRC",
                    "Refund %",
                    "Back to store",
                    "Condition",
                  ]}
                  style={{
                    marginTop: "1.5rem",
                    width: "100%",
                    overflow: "visible",
                  }}
                >
                  {reorganizedProductsToTable.map(
                    (product: OrderRefundItem) => (
                      <RefundTableRow key={product.productID}>
                        <td style={{ width: "16rem" }}>
                          <S.CheckBoxRow title={product.name}>
                            <div>
                              <Checkbox
                                checked={refundItems.some(
                                  (items) =>
                                    items.saleLineID === product.saleLineID,
                                )}
                                disabled={loading}
                                onClick={() => {
                                  addOrRemoveItem(product);
                                  setChecked(!checked);
                                }}
                              />
                            </div>
                            {product.name}
                          </S.CheckBoxRow>
                        </td>
                        <td style={{ width: "5rem" }}>
                          {individualItemsQuantityElement(product)}
                        </td>
                        <td style={{ width: "7.5rem" }}>
                          <SelectInput
                            disabled={
                              !refundItems.some(
                                (items) =>
                                  items.saleLineID === product.saleLineID,
                              ) || loading
                            }
                            options={isEbay ? EbayRefundReason : RefundReason}
                            variant="resized"
                            onChange={(e) =>
                              handleChangeSelectInputs(
                                product.saleLineID,
                                "reason",
                                e.target.value,
                              )
                            }
                            value={
                              refundItems.find(
                                (item) =>
                                  item.saleLineID === product.saleLineID,
                              )?.reason ||
                              (isEbay ? "BUYER_CANCEL" : "customer")
                            }
                          />
                        </td>
                        <td style={{ width: "10rem" }}>
                          <SelectInput
                            disabled={
                              !refundItems.some(
                                (items) =>
                                  items.saleLineID === product.saleLineID,
                              ) || loading
                            }
                            options={InternalReasonRefund}
                            onChange={(e) => {
                              handleChangeSelectInputs(
                                product.saleLineID,
                                "internalReason",
                                e.target.value,
                              );
                              setErrorMessage("");
                            }}
                            variant="resized"
                            error={errorMessage}
                          />
                        </td>
                        <td style={{ width: "5.7rem" }}>
                          <SelectInput
                            disabled={
                              !refundItems.some(
                                (items) =>
                                  items.saleLineID === product.saleLineID,
                              ) || loading
                            }
                            options={RefundPercentage}
                            variant="resized"
                            onChange={(e) =>
                              handleChangeSelectInputs(
                                product.saleLineID,
                                "refundPercentage",
                                e.target.value,
                              )
                            }
                            value={
                              refundItems.find(
                                (item) =>
                                  item.saleLineID === product.saleLineID,
                              )?.refundPercentage || "100%"
                            }
                          />
                        </td>
                        <td style={{ width: "11rem" }}>
                          <SearchableSelectInput
                            initialValue={StockLocationInitialValue[0]}
                            options={allStores}
                            onOptionSelect={(e) => {
                              handleChangeSelectInputs(
                                product.saleLineID,
                                "stockLocation",
                                e.code,
                                e.tenantName,
                              );
                            }}
                            width={"5rem"}
                            disabled={
                              !refundItems.some(
                                (items) =>
                                  items.saleLineID === product.saleLineID,
                              ) || loading
                            }
                          />
                        </td>
                        <td style={{ width: "8rem" }}>
                          <SelectInput
                            disabled={
                              !refundItems.some(
                                (items) =>
                                  items.saleLineID === product.saleLineID,
                              ) || loading
                            }
                            options={RefundCondition}
                            variant="resized"
                            onChange={(e) =>
                              handleChangeSelectInputs(
                                product.saleLineID,
                                "condition",
                                e.target.value,
                              )
                            }
                            value={
                              refundItems.find(
                                (item) =>
                                  item.saleLineID === product.saleLineID,
                              )?.condition || "New"
                            }
                          />
                        </td>
                      </RefundTableRow>
                    ),
                  )}
                </RefundTable>
              )}
            </>
          )}

          <S.ButtonAlign>
            <Dialog.Close asChild>
              <Button
                variant="ghost"
                title="Cancel"
                width="8.2rem"
                disabled={loading}
              />
            </Dialog.Close>
            <Button
              title="Submit Refund"
              width="8.2rem"
              onClick={isEbay ? handleEbaySubmitRefund : handleSubmitRefund}
              disabled={
                (refundType === "individual" && !refundItems[0]) || loading
              }
              isLoading={loading}
            />
          </S.ButtonAlign>
        </>
      )}
    </S.ModalContainer>
  );
}
